import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Topnav from './Components/Topnav/Topnav';
import Sidenav from './Components/Sidenav/Sidenav';
import SectionPage from './Components/Settings/SectionPage';
import ImpactPage from './Components/Settings/ImpactPage';
import PreferencePage from './Components/Settings/PreferencePage';
import UseCasePage from './Components/Settings/UseCasePage';
import FetchCard from './Components/Cards/FetchCard';
import LoadingBar from 'react-top-loading-bar';
import { Layout,TourProps  } from 'antd';
import Settingstab from './Components/Settings/Settingstab';
import LandingPage from './Components/LandingPage/LandingPage';
import UpdatesPage from './Components/Settings/UpdatesPage';
import { API_SERVER } from './Config';
import { MsalProvider, useMsal, useIsAuthenticated, MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { pca } from '.';
import LoginError from './Components/Error/LoginError';
import { AuthenticationResult, InteractionType, EventMessage, EventType, AuthError } from "@azure/msal-browser";
// import { io } from "socket.io-client";

import * as config from './Config';
import './App.css';

const { Content } = Layout;
// const socket = io("ws://localhost:3001");
  
const App: React.FC = () => {
  const { accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated();

  function errorComponent() {
    return <UnauthenticatedTemplate><LoginError /></UnauthenticatedTemplate>
  }

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  return (
    <>

      {pca.getAllAccounts().length!==0 ?
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate authenticationRequest={{ scopes: config.scopeBase, account: pca.getAllAccounts()[0] }}
            errorComponent={errorComponent}
            interactionType={InteractionType.Silent}
            loadingComponent={LoadingComponent}
          >
            <Pages />
          </MsalAuthenticationTemplate>
        </MsalProvider>
        : <MsalProvider instance={pca}><MsalAuthenticationTemplate authenticationRequest={{ scopes: config.scopeBase }}
          errorComponent={errorComponent}
          interactionType={InteractionType.Redirect}
          loadingComponent={LoadingComponent}
        >
          <Pages />
        </MsalAuthenticationTemplate>
        </MsalProvider>
      }

    </>
  );
};

const Pages = () => {
  //state and ref initialization
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [progress, setProgress] = useState(0);
  const settingsRef = useRef(null);
  const notificationsRef = useRef(null);
  const homeRef = useRef(null);
  const sidenavRef = useRef(null);
  const defaultRef = useRef(null);
  const impactRef = useRef(null);
  const tableRef = useRef(null);
  const editRef = useRef(null);
  const addRef = useRef(null);
  const tabRef = useRef(null);
  const multiselectRef = useRef(null);
  const updateRef = useRef(null);
  const dashboardRef = useRef(null);
  const deleteRef = useRef(null);
  const titleRef = useRef(null);
  const refreshRef = useRef(null);
  const downloadRef = useRef(null);
  const pbiviewRef = useRef(null);
  const backRef = useRef(null);
  const pbidashboardRef = useRef(null);
  const [menuItems, setMenuItems] = useState([]);
  const [impact, setImpact] = useState(null);
  const [updates, setUpdates] = useState(null);
  const [isVisited, setIsVisited] = useState(false)
  const [page, setPage] = useState("landing");
  const [firstlogin, setLogin] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [usecaseauto, setUsecaseauto] = useState([]); 
  const [showdashboard, setShowDashboard] = useState(false);
  const [username, setUserName] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [usecase, setUsecase] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [fulllink, setFullLink] = useState("");
  const [notifications, setNotifications] = useState([]);

  pca.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });

  const getAccessToken = async () => {
    let request = {
      scopes: config.scopeBase,
      redirectURI: config.APP_URL,
      account: pca.getAllAccounts()[0]
    };
    await instance.acquireTokenSilent(request).then((tokenResponse: any) => {
      // Do something with the tokenResponse
      localStorage.setItem('AT', tokenResponse.accessToken)
    }).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        let request = {
          scopes: config.scopeBase,
          // account: [accounts[0]]
          redirectURI: config.APP_URL
        };
        return await pca.acquireTokenPopup(request)
      }
      // handle other errors
    });
  }

const getaccess = async ()=>{
  try{
      if(pca.getAllAccounts.length>0)
      {
        // console.log('getting pbi token silently');
        await instance.acquireTokenSilent({
          scopes: config.scopeBase,
          account: pca.getAllAccounts()[0]
        }).then((response: any) => {
          setUserName(response['account']['name']);
          setUserEmail(response['account']['username']);
          fetchsections();
          fetchImpacts();
          fetchUpdates();
          fetchdashboard();
          localStorage.setItem('AT', response.accessToken);
          setLogin(true);
          // getAccessToken();
        })
          .catch(async (err) => {
            if (err instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              let request = {
                scopes: config.scopeBase,
                redirectURI: config.APP_URL
              };
              await instance.acquireTokenPopup(request).then((response: any) => {
                setUserName(response['account']['name']);
                setUserEmail(response['account']['username']);
                fetchsections();
                fetchImpacts();
                fetchUpdates();
                fetchdashboard();
                localStorage.setItem('AT', response.accessToken);
                setLogin(true);
              })
            }
          })
      }
      else
      {
        // console.log('getting pbi token via popup');
        await instance.acquireTokenPopup({
          scopes: config.scopeBase,
          // account: pca.getAllAccounts()[0]
        }).then((response: any) => {
          setUserName(response['account']['name']);
          setUserEmail(response['account']['username']);
          fetchsections();
          fetchImpacts();
          fetchUpdates();
          fetchdashboard();
          localStorage.setItem('AT', response.accessToken);
          setLogin(true);
          // getAccessToken();
        })
          .catch(async (err) => {
            if (err instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              let request = {
                scopes: config.scopeBase,
                redirectURI: config.APP_URL
              };
              await instance.acquireTokenRedirect(request).then((response: any) => {
                setUserName(response['account']['name']);
                setUserEmail(response['account']['username']);
                fetchsections();
                fetchImpacts();
                fetchUpdates();
                fetchdashboard();
                localStorage.setItem('AT', response.accessToken);
                setLogin(true);
              })
            }
          })
      }
      
    }
    catch (err) {
      console.log(err);
    }
}
  useEffect(() => {
    setPage('landing');
      if(isAuthenticated)
      {
        window.setInterval(async () => { getAccessToken() }, 55 * 60 * 1000); // refreshes access token after every 55mins
        getaccess();
        
        // socket.on('connect',()=>{console.log('connected')});
        // socket.emit('message',()=>{console.log('message emitted')});
        // socket.on("message", (data: any) => {
        //   console.log('received message',data);
        //   let val: any=[];
        //   if(notifications.length!=0)
        //     val.push(...notifications);
        //   val.push({id:notifications.length,message:data,read:false});
        //   setNotifications(val);
        // }); 
      }
      setUserName(''+accounts[0]['name']);
      setUserEmail(accounts[0]['username']);
      fetchsections();
      fetchImpacts();
      fetchUpdates();
      fetchdashboard();

      // socket.emit('message',()=>{console.log('message emitted')});
      //   socket.on("message", (data: any) => {
      //     console.log('received message',data);
      //     let val: any=[];
      //     if(notifications.length!=0)
      //       val.push(...notifications);
      //     val.push({id:notifications.length,message:data,read:false});
      //     setNotifications(val);
      //   }); 
  },[isAuthenticated]);

  //Fetching dashboard
  const fetchdashboard = () => {
    try {
      fetch(`${API_SERVER}usecase/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: any) => {
          setDashboard(apiData);
          setUsecaseauto([]);
          let val:any=[];
          for(let i=0;i<apiData.length;i++)
            val.push({value:apiData[i]['Usecase_Name']})
          setUsecaseauto(val);
        })
        .catch((error) => {
          console.error("Error fetching usecases:", error);
        });
    }
    catch (err) {
      console.log(err);
    }
  };

  // Fetching section names and description
  const fetchsections = () => {
    try {
      fetch(`${API_SERVER}section/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: any) => {
          let val: any = [];
          for (let i = 0; i < apiData.length; i++) {
            val.push({
              label: apiData[i]["Section_Name"],
              key: (i + 1).toString(),
              path: "/" + apiData[i]["Section_Name"].toLowerCase().replaceAll(" ", "-"),
              description: apiData[i]['Description']
            })
          }
          setMenuItems(val);
        })
        .catch((error) => {
          console.error("Error fetching sections:", error);
        });
    }
    catch (err) {
      console.log(err);
    }
  };

  // Fetching impacts
  const fetchImpacts = () => {
    try {
      fetch(`${API_SERVER}impact/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: any) => {
          let val: any = [];
          for (let i = 0; i < apiData.length; i++) {
            val.push({
              sectionname: apiData[i]["Section_Name"],
              description: apiData[i]["Impact_Description"]
            })
          }
          setImpact(val);
        })
        .catch((error) => {
          console.error("Error fetching impacts:", error);
        });
    }
    catch (err) {
      console.log(err);
    }
  };

  // Fetching updates 
  const fetchUpdates = () => {
    try {
      fetch(`${API_SERVER}updates/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: any) => {
          let val: any = [];
          for (let i = 0; i < apiData.length; i++) {
            val.push({
              sectionname: apiData[i]["Section_Name"],
              usecasename: apiData[i]["Usecase_name"],
              description: apiData[i]['Description']
            });
          }
          setUpdates(val);
        })
        .catch((error) => {
          console.error("Error fetching updates:", error);
        });
    }
    catch (err) {
      console.log(err)
    }
  };

  //tour steps for guide tour
  const steps: TourProps['steps'] = [
    {
      title: 'Home',
      description: 'Click here to go to landing page.',
      target: () => homeRef.current,
    },
    {
      title: 'Settings',
      description: 'User can interact with different backend tables here.',
      target: () => settingsRef.current,
    },
    {
      title: 'Notifications',
      description: 'Click here to check notifications.',
      target: () => notificationsRef.current,
    },
    {
      title: 'Sections',
      description: 'User can explore different sections using this side navigation bar.',
      target: () => sidenavRef.current,
      placement: "left",
    },
    {
      title: 'Description of Usecase',
      description: 'User can see brief description of the section here.',
      target: () => defaultRef.current,
    },
    {
      title: 'Usecases',
      description: 'Different usecases of selected section are displayed here.',
      target: () => dashboardRef.current,
      placement: "top",
    },
    {
      title: 'Updates',
      description: 'User can see selected section related updates here.',
      target: () => updateRef.current,
    },
    {
      title: 'Impacts',
      description: 'User can see impacts generated by different usecases in this section.',
      target: () => impactRef.current,
      placement: "left",
    },
  ];

  const stepssetting: TourProps['steps'] = [
    {
      title: 'Home',
      description: 'Click here to go to landing page.',
      target: () => homeRef.current,
    },
    {
      title: 'Settings',
      description: 'User can interact with different backend tables here.',
      target: () => settingsRef.current,
    },
    {
      title: 'Notifications',
      description: 'Click here to check notifications.',
      target: () => notificationsRef.current,
    },
    {
      title: 'Sections',
      description: 'User can explore different sections using this side navigation bar.',
      target: () => sidenavRef.current,
      placement: "left",
    },
    {
      title: 'Tabs',
      description: 'Allows user to navigate between different backend tables.',
      target: () => tabRef.current,
      placement: "bottom",
    },
    {
      title: 'Backend Table',
      description: 'Table for selected tab.',
      target: () => tableRef.current,
    },
    {
      title: 'Multi select icon',
      description: 'Allows user to select multiple rows. Once selected user can perform multi delete.',
      target: () => multiselectRef.current,
    },
    {
      title: 'Edit and Delete icon',
      description: 'Allows user to edit and delete specific row. If multiple rows are selected user can perform multi delete using delete icon',
      target: () => editRef.current,
    },
    {
      title: 'Add new record button',
      description: 'Allows user to add new record in the current table.',
      target: () => addRef.current,
      placement: "right",
    },
  ];

  const prefstepssetting: TourProps['steps'] = [
    {
      title: 'Home',
      description: 'Click here to go to landing page.',
      target: () => homeRef.current,
    },
    {
      title: 'Settings',
      description: 'User can interact with different backend tables here.',
      target: () => settingsRef.current,
    },
    {
      title: 'Notifications',
      description: 'Click here to check notifications.',
      target: () => notificationsRef.current,
    },
    {
      title: 'Sections',
      description: 'User can explore different sections using this side navigation bar.',
      target: () => sidenavRef.current,
      placement: "left",
    },
    {
      title: 'Tabs',
      description: 'Allows user to navigate between different backend tables.',
      target: () => tabRef.current,
      placement: "bottom",
    },
    {
      title: 'Backend Table',
      description: 'Table for selected tab.',
      target: () => tableRef.current,
    },
    {
      title: 'Multi select icon',
      description: 'Allows user to select multiple rows. Once selected user can perform multi delete.',
      target: () => multiselectRef.current,
    },
    {
      title: 'Delete icon',
      description: 'Allows user to delete specific row. If multiple rows are selected user can perform multi delete using delete icon',
      target: () => deleteRef.current,
    },
    {
      title: 'Add new record button',
      description: 'Allows user to add new record in the current table.',
      target: () => addRef.current,
      placement: "right",
    }
  ];

  const Dashboardsteps: TourProps['steps'] = [
    {
      title: 'Home',
      description: 'Click here to go to landing page.',
      target: () => homeRef.current,
    },
    {
      title: 'Settings',
      description: 'User can interact with different backend tables here.',
      target: () => settingsRef.current,
    },
    {
      title: 'Notifications',
      description: 'Click here to check notifications.',
      target: () => notificationsRef.current,
    },
    {
      title: 'Sections',
      description: 'User can explore different sections here',
      target: () => sidenavRef.current,
      placement: "left",
    },
    {
      title: 'Usecase description',
      description: 'Title and Description of Usecase',
      target: () => titleRef.current,
    },
    {
      title: 'Refresh Dashboard',
      description: 'Click here to refresh access token for dashboard.',
      target: () => refreshRef.current,
    },
    {
      title: 'Export as PDF',
      description: 'Click here to download dashboard as PDF.',
      target: () => downloadRef.current,
    },
    {
      title: 'View in PowerBI Web App',
      description: 'Click here to view the dashboard in Power BI Web App',
      target: () => pbiviewRef.current,
    },
    {
      title: 'Go back to main cockpit page',
      description: 'Click here to go back to the main cockpit page.',
      target: () => backRef.current,
    },
    {
      title: 'PowerBI dashboard',
      description: 'User can interact with the dashboard here',
      target: () => pbidashboardRef.current,
    },

  ];

  return (
    <>
      {
        isAuthenticated ?
          <>
            <Router>
              <Layout style={{ minHeight: '100vh' }}>
                {page && usecaseauto &&  <Topnav 
                homeRef={homeRef} 
                steps={steps} 
                stepssetting={stepssetting} 
                settingsRef={settingsRef} 
                prefstepssetting={prefstepssetting} 
                notificationsRef={notificationsRef} 
                page={page} 
                Dashboardsteps={Dashboardsteps} 
                usecaseauto={usecaseauto} 
                menuItems={menuItems}
                dashboard={dashboard}
                setUsecase={setUsecase}
                setDescription={setDescription}
                setLink={setLink}
                setFullLink={setFullLink}
                usecase={usecase}
                description={description}
                link={link}
                fulllink={fulllink}
                setPage={setPage}
                setShowDashboard={setShowDashboard}
                // notifications={notifications}
                // setNotifications={setNotifications}
                />}
                <Layout>
                  <Routes>
                    <Route
                      path="/"
                      element={<LandingPage menuItems={menuItems} isVisited={isVisited} setIsVisited={setIsVisited} />} />
                    <Route
                      path="/settings/*"
                      element={
                        <Layout>
                          {page && <Sidenav menuItems={menuItems} sidenavRef={sidenavRef} page={page} setShowDashboard={setShowDashboard} dashboard={dashboard}/>}
                          <Layout>
                            <Settingstab selectedTab={''}  tabRef={tabRef} />
                            <Content style={{ minHeight: 'calc(100vh - 116px)' }}>
                              <LoadingBar color='#FFFFFF' progress={progress} onLoaderFinished={() => setProgress(0)} />
                              <Routes>
                                <Route path="/section" element={<SectionPage
                                  setProgress={setProgress}
                                  setMenuItems={setMenuItems}
                                  setPage={setPage}
                                  tableRef={tableRef}
                                  editRef={editRef}
                                  deleteRef={defaultRef}
                                  addRef={addRef}
                                  multiselectRef={multiselectRef} 
                                  usecaseauto={usecaseauto}
                                  setUsecaseauto={setUsecaseauto}
                                  fetchdashboard={fetchdashboard}
                                  />} />
                                <Route path="/use-case" element={<UseCasePage setProgress={setProgress}
                                  setMenuItems={setMenuItems}
                                  setPage={setPage}
                                  tableRef={tableRef}
                                  editRef={editRef}
                                  deleteRef={defaultRef}
                                  addRef={addRef}
                                  multiselectRef={multiselectRef} 
                                  usecaseauto={usecaseauto}
                                  setUsecaseauto={setUsecaseauto}
                                  setDashboard={setDashboard}
                                  />} />
                                <Route path="/preference" element={<PreferencePage
                                  setProgress={setProgress}
                                  setMenuItems={setMenuItems}
                                  setPage={setPage}
                                  tableRef={tableRef}
                                  deleteRef={deleteRef}
                                  addRef={addRef}
                                  multiselectRef={multiselectRef}
                                  username={username}
                                  useremail={useremail}
                                />} />
                                <Route path="/impact" element={<ImpactPage
                                  setProgress={setProgress}
                                  setImpact={setImpact}
                                  setPage={setPage}
                                  tableRef={tableRef}
                                  editRef={editRef}
                                  deleteRef={defaultRef}
                                  addRef={addRef}
                                  multiselectRef={multiselectRef} />} />
                                <Route path="/updates" element={<UpdatesPage
                                  setProgress={setProgress}
                                  setUpdates={setUpdates}
                                  setPage={setPage}
                                  tableRef={tableRef}
                                  editRef={editRef}
                                  deleteRef={defaultRef}
                                  addRef={addRef}
                                  multiselectRef={multiselectRef} />} />
                              </Routes>
                            </Content>
                          </Layout>
                        </Layout>
                      }
                    />
                    <Route
                      path='/*'
                      element={
                        <Layout>
                          {page && <Sidenav menuItems={menuItems} sidenavRef={sidenavRef} page={page} setShowDashboard={setShowDashboard} dashboard={dashboard} />}
                          <Layout>
                            <Content style={{ minHeight: 'calc(100vh - 116px)' }}>
                              <LoadingBar color='white' progress={progress} onLoaderFinished={() => setProgress(0)} />
                              <Routes>
                                <Route path="/" />
                                {
                                  menuItems.length !== 0 && menuItems.map((val: any) => {
                                    return <Route path={val["path"]} element={<FetchCard
                                      setProgress={setProgress}
                                      defaultRef={defaultRef}
                                      impactRef={impactRef}
                                      label={val["label"]}
                                      sectiondescription={val["description"]}
                                      impact={impact}
                                      updates={updates}
                                      updateRef={updateRef}
                                      dashboardRef={dashboardRef}
                                      setPage={setPage}
                                      showdashboard={showdashboard}
                                      setShowDashboard={setShowDashboard}
                                      titleRef={titleRef}
                                      refreshRef={refreshRef}
                                      downloadRef={downloadRef}
                                      pbiviewRef={pbiviewRef}
                                      backRef={backRef}
                                      pbidashboardRef={pbidashboardRef}
                                      username={username}
                                      useremail={useremail}
                                      setUsecase={setUsecase}
                                      setDescription={setDescription}
                                      setLink={setLink}
                                      setFullLink={setFullLink}
                                      usecase={usecase}
                                      description={description}
                                      link={link}
                                      fulllink={fulllink} currentupdate={''}                                    />
                                    }
                                    />
                                  })
                                }
                              </Routes>
                            </Content>
                          </Layout>
                        </Layout>
                      }
                    />
                  </Routes>
                </Layout>
              </Layout>
            </Router>
          </> : null
      }
    </>
  );
}

export default App;