import React, { useState, useEffect } from "react";
import { Table, Space, Select } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {Button,Drawer,Input,Popconfirm,Form,Checkbox,message} from "antd";
import {EditOutlined,CloseOutlined,QuestionCircleOutlined,} from "@ant-design/icons";
import "./SettingsPage.css";
import LoadingBar from "react-top-loading-bar";
import TextArea from "antd/es/input/TextArea";
import { API_SERVER } from "../../Config";
import * as config from "../../Config";

interface DataType {
  Section_Name: string;
  Usecase_Name: string;
  Description: string;
  Link: string;
  isChecked: boolean;
}

interface UsecasePageProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setMenuItems: any;
  setPage: any;
  tableRef: any;
  editRef: any;
  deleteRef: any;
  addRef: any;
  multiselectRef: any;
  usecaseauto: any;
  setUsecaseauto: any;
  setDashboard: any;
  // setInsideSettings: any;
}

const UseCasePage: React.FC<UsecasePageProps> = ({
  setProgress,
  setMenuItems,
  setPage,
  tableRef,
  editRef,
  deleteRef,
  addRef,
  multiselectRef,
  usecaseauto,
  setUsecaseauto,
  setDashboard
}) => {
  const [data, setData] = useState<DataType[]>([]);
  const [sectionnameFilters, setSectionnameFilters] = useState<
    Array<{ text: string; value: string }>
  >([]);
  const [usecasenameFilters, setUsecasenameFilters] = useState<
    Array<{ text: string; value: string }>
  >([]);
  const [sectionnameDropdown, setSectionnameDropdown] = useState<
    Array<{ text: string; value: string }>
  >([]);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState(false);
  const [oldSectionName, setOldSectionName] = useState<string>("");
  const [oldUseCaseName, setOldUseCaseName] = useState<string>("");
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    if (source) {
      onReset();
    }
    setSource(false);

    // form.resetFields();
  };

  function getsectionaname() {
    try {
      fetch(`${API_SERVER}section/find`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => response.json())
        .then((apiData) => {
          let val: any = [];
          for (let i = 0; i < apiData.length; i++)
            val.push({
              text: apiData[i]["Section_Name"],
              value: apiData[i]["Section_Name"],
            });

          setSectionnameDropdown(val);
          setProgress(100);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // setInsideSettings(true);
    setPage("settings");
    setProgress(50);
    try {
      fetch(`${API_SERVER}usecase/find`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => response.json())
        .then((apiData: DataType[]) => {
          setDashboard(apiData);
          for (let i = 0; i < apiData.length; i++)
            apiData[i]["isChecked"] = false;

          setData(apiData);
          getsectionaname();
          const uniqueSectionNames = Array.from(
            new Set(apiData.map((item: DataType) => item.Section_Name))
          );
          const dynamicSectionNamesFilters: { text: string; value: string }[] =
            uniqueSectionNames.map((sectionName) => ({
              text: sectionName as string,
              value: sectionName as string,
            }));
          setSectionnameFilters(dynamicSectionNamesFilters);

          const uniqueUsecaseNames = Array.from(
            new Set(apiData.map((item: DataType) => item.Usecase_Name))
          );
          const dynamicUsecaseFilters: { text: string; value: string }[] =
            uniqueUsecaseNames.map((usecaseName) => ({
              text: usecaseName as string,
              value: usecaseName as string,
            }));
          setUsecasenameFilters(dynamicUsecaseFilters);

          setProgress(100);
          let rows: any = document.getElementsByClassName("ant-table-row");
          for (let i = 0; i < rows.length; i++) {
            rows[i].addEventListener("click", (event: any) => {
              try {
                let nodes = event.target.parentNode;
                nodes.childNodes[
                  nodes.childNodes.length - 1
                ].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].click();
              } catch (err) {}
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handlecheckbox = (e: any) => {
    const { value, checked } = e.target;
    setData(prevData =>
      prevData.map(item =>
        item.Section_Name === value["Section_Name"] &&
        item.Usecase_Name === value["UseCase_Name"]
          ? { ...item, isChecked: checked }
          : item
      )
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Section Name",
      dataIndex: "Section_Name",
      key: "Section_Name",
      filters: sectionnameFilters,
      onFilter: (
        value: string | number | boolean | bigint | undefined,
        record
      ) => record.Section_Name === value,
      sorter: (a, b) => a.Section_Name.localeCompare(b.Section_Name),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: "Usecase Name",
      dataIndex: "Usecase_Name",
      key: "Usecase_Name",
      filters: usecasenameFilters,
      className: 'ant-table-cell-ellipsis',
      onFilter: (
        value: string | number | boolean | bigint | undefined,
        record
      ) => record.Usecase_Name === value,
      sorter: (a, b) => a.Usecase_Name.localeCompare(b.Usecase_Name),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "50%",
      sorter: (a, b) => a.Description.localeCompare(b.Description),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: "Link",
      dataIndex: "Link",
      key: "Link",
      render: (text) => <a>{text}</a>,
      ellipsis: true,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <span ref={multiselectRef}>
            <Checkbox
              checked={
                data.filter(
                  (val) =>
                    val["Section_Name"] === record["Section_Name"] &&
                    val["Usecase_Name"] === record["Usecase_Name"]
                )[0]["isChecked"]
              }
              value={{
                Section_Name: record.Section_Name,
                UseCase_Name: record.Usecase_Name,
                Link: record.Link,
                Description: record.Description,
              }}
              onChange={handlecheckbox}
            ></Checkbox>
          </span>
          <span ref={editRef}>
            <Button
              disabled={data.filter((val) => val["isChecked"]).length > 1}
              type="link"
              onClick={(event) => {
                setSource(true);
                form.setFieldsValue({
                  Section_Name: record["Section_Name"],
                  Usecase_Name: record["Usecase_Name"],
                  Description: record["Description"],
                  Link: record["Link"],
                });
                setOldSectionName(record["Section_Name"]);
                setOldUseCaseName(record["Usecase_Name"]);
                showDrawer();
              }}
              icon={<EditOutlined style={{ color: "green" }} />}
            />

            <Popconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => handleDelete(record)}
              onCancel={() => handleCancel(record)}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              ref={deleteRef}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  data.filter((val) => val["isChecked"]).length > 1 &&
                  !record.isChecked
                }
                type="link"
                icon={<CloseOutlined style={{ color: "red" }} />}
              />
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  const checkembedUrlforadding = (link: string, value: any) => {
    try {
      
      fetch(
        config.powerBiApiUrl +
          "v1.0/myorg/groups/" +
          config.workspaceId +
          "/reports/" +
          link,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AT"),
          },
          method: "GET",
        }
      )
        .then((response) => {
          setProgress(50);
          response
            .json()
            .then((body) => {
              setProgress(75);
              if (Object.keys(body).includes("embedUrl")) {
                try {
                  fetch(`${API_SERVER}usecase/add/`, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(value),
                  }).then((res) => {
                    fetch(`${API_SERVER}usecase/find`, {
                      method: "GET",
                      mode: "cors",
                    })
                      .then((response) => response.json())
                      .then((apiData) => {
                        setDashboard(apiData);
                        setData(apiData);
                        setUsecaseauto([]);
                        let val: any = [];
                        for (let i = 0; i < apiData.length; i++)
                          val.push({ value: apiData[i]["Usecase_Name"] });
                        setUsecaseauto(val);
                        setProgress(100);
                        onReset();
                      });
                  });
                } catch (err) {}
              } else {
                message.error(
                  "Link is not added because URL is invalid or you don't have access to dashboard!",
                  5
                );
                showDrawer();
                form.setFieldsValue({
                  Section_Name: value["Section_Name"],
                  Usecase_Name: value["Usecase_Name"],
                  Description: value["Description"],
                  Link: ''
                });
                setProgress(100);
              }
            })
            .catch((err) => {
              message.error(
                "Link is not added because URL is invalid or you don't have access to dashboard!",
                5
              );
              showDrawer();
              form.setFieldsValue({
                Section_Name: value["Section_Name"],
                Usecase_Name: value["Usecase_Name"],
                Description: value["Description"],
                Link: ''
              });
              setProgress(100);
            });
        })
        .catch(function (error) {
          message.error(
            "Link is not added because URL is invalid or you don't have access to dashboard!",
            5
          );
          showDrawer();
          form.setFieldsValue({
            Section_Name: value["Section_Name"],
            Usecase_Name: value["Usecase_Name"],
            Description: value["Description"],
            Link: ''
          });
          setProgress(100);
        });
    } catch (err) {
      message.error(
        "Link is not added because URL is invalid or you don't have access to dashboard!",
        5
      );
      showDrawer();
      form.setFieldsValue({
        Section_Name: value["Section_Name"],
        Usecase_Name: value["Usecase_Name"],
        Description: value["Description"],
        Link: ''
      });
      setProgress(100);
    }
  };

  const checkembedUrlforupdating = (link: string, value: any) => {
    try {
      fetch(
        config.powerBiApiUrl +
          "v1.0/myorg/groups/" +
          config.workspaceId +
          "/reports/" +
          link,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AT"),
          },
          method: "GET",
        }
      )
        .then((response) => {
          setProgress(50);
          response
            .json()
            .then((body) => {
              setProgress(75);
              if (Object.keys(body).includes("embedUrl")) {
                try {
                  fetch(
                    `${API_SERVER}usecase/update/${oldSectionName}/${oldUseCaseName}`,
                    {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      mode: "cors",
                      body: JSON.stringify(value),
                    }
                  ).then((res) => {
                    fetch(`${API_SERVER}usecase/find`, {
                      method: "GET",
                      mode: "cors",
                    })
                      .then((response) => response.json())
                      .then((apiData) => {
                        setData(apiData);
                        setDashboard(apiData);
                        setUsecaseauto([]);
                        let val: any = [];
                        for (let i = 0; i < apiData.length; i++)
                          val.push({ value: apiData[i]["Usecase_Name"] });
                        setUsecaseauto(val);
                        setProgress(100);
                      });
                  });
                } catch (err) {
                  console.log(err);
                }
              } else {
                message.error(
                  "Link is not updated because URL is invalid or you don't have access to dashboard!",
                  5
                );
                showDrawer();
                form.setFieldsValue({
                  Section_Name: value["Section_Name"],
                  Usecase_Name: value["Usecase_Name"],
                  Description: value["Description"],
                  Link: ''
                });
                setProgress(100);
              }
            })
            .catch((err) => {
              message.error(
                "Link is not updated because URL is invalid or you don't have access to dashboard!",
                5
              );
              showDrawer();
              form.setFieldsValue({
                Section_Name: value["Section_Name"],
                Usecase_Name: value["Usecase_Name"],
                Description: value["Description"],
                Link: ''
              });
              setProgress(100);
            });
        })
        .catch(function (error) {
          message.error(
            "Link is not updated because URL is invalid or you don't have access to dashboard!",
            5
          );
          showDrawer();
          form.setFieldsValue({
            Section_Name: value["Section_Name"],
            Usecase_Name: value["Usecase_Name"],
            Description: value["Description"],
            Link: ''
          });
          setProgress(100);
        });
    } catch (err) {
      message.error(
        "Link is not updated because URL is invalid or you don't have access to dashboard!",
        5
      );
      showDrawer();
      form.setFieldsValue({
        Section_Name: value["Section_Name"],
        Usecase_Name: value["Usecase_Name"],
        Description: value["Description"],
        Link: ''
      });
      setProgress(100);
    }
  };

  const insertrecord = async (value: any) => {
    setProgress(50);
    if(!value['Link'].startsWith('https'))
      value['Link']='https://'+value['Link'];
    checkembedUrlforadding(value["Link"].split("/")[6], value);
  };

  const updaterecord = (value: any) => {
    setProgress(50);
    if(!value['Link'].startsWith('https'))
      value['Link']='https://'+value['Link'];
    checkembedUrlforupdating(value["Link"].split("/")[6], value);
  };

  const handleUsecase = (value: any) => {
    if (!source) {
      insertrecord(value);
    } else {
      updaterecord(value);
    }
    onClose();
  };

  const handleDelete = (record: DataType) => {
    let check = 0;
    for (let i = 0; i < data.length; i++) if (data[i]["isChecked"]) check += 1;

    if (check === 0) {
      setProgress(50);
      try {
        fetch(
          `${API_SERVER}usecase/delete/${record["Section_Name"]}/${record["Usecase_Name"]}`,
          {
            method: "DELETE",
            mode: "cors",
          }
        )
          .then((res) => {
            fetch(`${API_SERVER}usecase/find`, {
              method: "GET",
              mode: "cors",
            })
              .then((response) => response.json())
              .then((apiData) => {
                setData(apiData);
                setDashboard(apiData);
                setUsecaseauto([]);
                let val: any = [];
                for (let i = 0; i < apiData.length; i++)
                  val.push({ value: apiData[i]["Usecase_Name"] });
                setUsecaseauto(val);
                // add use case updated values for main cockpit page here
                setProgress(100);
              });
          })
          .catch((error) => console.error("Error deleting record:", error));
      } catch (err) {
        console.log(err);
      }
    } else {
      let count: number = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["isChecked"]) {
          try {
            fetch(
              `${API_SERVER}usecase/delete/${data[i]["Section_Name"]}/${data[i]["Usecase_Name"]}`,
              {
                method: "DELETE",
                mode: "cors",
              }
            )
              .then((res) => {
                fetch(`${API_SERVER}usecase/find`, {
                  method: "GET",
                  mode: "cors",
                })
                  .then((response) => response.json())
                  .then((apiData) => {
                    setData(apiData);
                    setDashboard(apiData);
                    setUsecaseauto([]);
                    let val: any = [];
                    for (let i = 0; i < apiData.length; i++)
                      val.push({ value: apiData[i]["Usecase_Name"] });
                    setUsecaseauto(val);
                    count += 1;
                    // add use case updated values for main cockpit page here
                  });
              })
              .catch((error) => console.error("Error deleting record:", error));
          } catch (err) {
            console.log(err);
          }
          setProgress((count / check) * 100);
        }
      }
    }
    setProgress(100);
  };

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
  };

  const handleCancel = (record: DataType) => {
    let rows: any = document.getElementsByClassName("ant-table-row");
    let updatedData = data.map((item) => ({ ...item, isChecked: false }));

    for (let i = 0; i < rows.length; i++) {
      rows[i].style.backgroundColor = "white";
    }

    setData(updatedData);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="usecaseset">
      <LoadingBar color="white" onLoaderFinished={() => setProgress(0)} />
      <div ref={tableRef}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          onChange={onChange}
          pagination={{ pageSize: 5 }}
          size="small"
          rowClassName={(record: DataType) =>
            record.isChecked ? 'checked-row' : ''
        }
        />
      </div>
      <div className="usecasebuttons">
        <Button
          className="usecasebutton"
          ref={addRef}
          type="primary"
          onClick={() => {
            showDrawer();
          }}
        >
          Add Usecase
        </Button>
      </div>

      <Drawer
        title="ADD USECASE"
        placement="right"
        onClose={onClose}
        open={open}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
        width={500}
      >
        <Form form={form} onFinish={handleUsecase}>
          <p> Section Name</p>

          <Form.Item
            name="Section_Name"
            rules={[
              { required: true, message: "Please enter Section Name!" },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/,
                message:
                  "Section Name should start with a letter and can only contain letters, numbers, and underscores.",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Enter Section Name"
              onChange={handleChange}
              options={sectionnameDropdown}
            />
          </Form.Item>

          <p> Usecase Name</p>
          <Form.Item
            name="Usecase_Name"
            rules={[
              { required: true, message: "Please enter Usecase Name!" },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/,
                message:
                  "Usecase Name should start with a letter and can only contain letters, numbers, and underscores.",
              },
              {
                max: 2000,
                message:"should not exceed more than 2000",
              }
            ]}
          >
            <Input placeholder="Enter Usecase Name" autoComplete="off" />
          </Form.Item>
          <p> Description</p>
          <Form.Item
            name="Description"
            
            rules={[
              { required: true, message: "Please enter Description!" },
              {
                pattern: /^[^\s].*$/,
                message: "Description should not start with a space!",
              },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message:
                  "Description can contain any characters, including special characters.",
              },
              {
                max: 2000,
                message:"should not exceed more than 2000",
              }
            ]}
          >
            <TextArea placeholder="Enter Description" autoComplete="off" showCount maxLength={2000}/>
          </Form.Item>
          <p> Link</p>
          <Form.Item
            name="Link"
            rules={[
              { required: true, message: "Please add link!" },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                max: 2000,
                message:"should not exceed more than 2000",
              }
            ]}
          >
            <Input placeholder="Add Link" autoComplete="off" />
          </Form.Item>
          <a className="Reset" onClick={onReset}>
            Reset
          </a>
          <Button
            className="Two"
            type="primary"
            htmlType="submit"
            style={{ marginTop: "18vh", alignItems: "center" }}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default UseCasePage;