import React, { useState, useEffect } from 'react';
import { Table, Space,Button, Drawer, Popconfirm, Form, Select , Checkbox } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EditOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import './SettingsPage.css';
import LoadingBar from 'react-top-loading-bar';
import { API_SERVER } from '../../Config';
interface DataType {
  Section_Name: string;
  Impact_Description: string;
  Usecase_Name: string;
  isChecked: boolean;
}

interface ImpactPageProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setImpact: any;
  setPage: any;
  tableRef: any;
  editRef: any;
  deleteRef: any;
  addRef: any;
  multiselectRef: any;
}

const ImpactPage: React.FC<ImpactPageProps> = ({ setProgress, setImpact, setPage, tableRef, editRef, deleteRef, addRef, multiselectRef }) => {
  const [data, setData] = useState<DataType[]>([]);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState(false);
  const [form] = Form.useForm();
  const [sectionFilters, setSectionFilters] = useState<Array<{ text: string, value: string }>>([]);
  const [usecaseFilters, setUsecaseFilters] = useState<Array<{ text: string, value: string }>>([]);
  const [oldSectionName, setOldSectionName] = useState<string>("");
  const [oldUseCaseName, setOldUseCaseName] = useState<string>("");
  const [oldImpactName, setOldImpactName] = useState<string>("");
  const [usecasedata, setusecasedata] = useState([]);
  const [sectionnameDropdown, setsectionnameDropdown] = useState<Array<{ text: string, value: string }>>([]);
  const [usecasenameDropdown, setusecasenameDropdown] = useState<Array<{ text: string, value: string }>>([]);


  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    if(source){
      onReset();
    }
    setSource(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  function getusecaseandsection(){
    fetch(`${API_SERVER}usecase/find`, {
      method: "GET",
      mode: "cors"
    })
      .then((response) => response.json())
      .then((apiData) => {
        setusecasedata(apiData)
        let usecase:Array<{ text: string, value: string }>=[];
        let section:Array<{ text: string, value: string }>=[];
        let usecasecheck:Array<string>=[];
        let sectioncheck:Array<string>=[];
        for(let i=0;i<apiData.length;i++)
        {
          if(!usecasecheck.includes(apiData[i]['Usecase_Name']))
          {
            usecase.push({text:apiData[i]['Usecase_Name'],value:apiData[i]['Usecase_Name']});
            usecasecheck.push(apiData[i]['Usecase_Name']);
          }
            
          if(!sectioncheck.includes(apiData[i]['Section_Name']))
          {
            section.push({text:apiData[i]['Section_Name'],value:apiData[i]['Section_Name']});
            sectioncheck.push(apiData[i]['Section_Name']);
          }
        }
        setsectionnameDropdown(section);
        setusecasenameDropdown(usecase);
    })
  }
  useEffect(() => {
    setPage('settings');
    setProgress(50);
    getusecaseandsection();
    fetch(`${API_SERVER}impact/find`, {
      method: "GET",
      mode: "cors"
    })
      .then((response) => response.json())
      .then((apiData: DataType[]) => {
        for (let i = 0; i < apiData.length; i++)
          apiData[i]['isChecked'] = false;
        setData(apiData);

        const uniqueSections: string[] = Array.from(new Set(apiData.map((item: DataType) => item.Section_Name)));
        const dynamicFilters: { text: string; value: string }[] = uniqueSections.map((section: string) => ({ text: section, value: section }));
        setSectionFilters(dynamicFilters);

        const uniqueUsecaseNames = Array.from(new Set(apiData.map((item: DataType) => item.Usecase_Name)));
        const dynamicUsecaseFilters: { text: string; value: string }[] = uniqueUsecaseNames.map((usecaseName) => ({ text: usecaseName as string, value: usecaseName as string }));
        setUsecaseFilters(dynamicUsecaseFilters);

        setProgress(100);
        let rows: any = document.getElementsByClassName('ant-table-row');
        for (let i = 0; i < rows.length; i++) {
          rows[i].addEventListener('click', (event: any) => {
            try {
              let nodes = event.target.parentNode;
              nodes.childNodes[nodes.childNodes.length - 1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].click();
            }
            catch (err) { }
          })
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handlecheckbox = (e: any) => {
    const { value, checked } = e.target;
    setData(prevData =>
      prevData.map(item =>
        item.Section_Name === value["Section_Name"] &&
        item.Usecase_Name === value["UseCase_Name"] &&
        item.Impact_Description === value["Impact_Description"]
          ? { ...item, isChecked: checked }
          : item
      )
    );
  };
  


  const columns: ColumnsType<DataType> = [
    {
      title: 'Section Name',
      dataIndex: 'Section_Name',
      key: 'Section_Name',
      filters: sectionFilters,
      ellipsis: true,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Section_Name === value,
      sorter: (a, b) => a.Section_Name.localeCompare(b.Section_Name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Usecase Name',
      dataIndex: 'Usecase_Name',
      key: 'Usecase_Name',
      filters: usecaseFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Usecase_Name === value,
      sorter: (a, b) => a.Usecase_Name.localeCompare(b.Usecase_Name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Impact Description',
      dataIndex: 'Impact_Description',
      key: 'Impact_Description',
      width: '50%',
      sorter: (a, b) => a.Impact_Description.localeCompare(b.Impact_Description),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '5vh',
      render: (_, record) => (
        <Space size="middle">
         <span ref={multiselectRef}>
        <Checkbox
            checked={data.filter((val) => val["Section_Name"] === record["Section_Name"] && val["Usecase_Name"] === record["Usecase_Name"] && val['Impact_Description']===record['Impact_Description'])[0]["isChecked"]}
            value={{ "Section_Name": record.Section_Name, "UseCase_Name": record.Usecase_Name, "Impact_Description": record.Impact_Description }} 
            onChange={handlecheckbox}
        ></Checkbox>
        </span>
        <span ref={editRef}>
          <Button 
          disabled={data.filter((val) => val["isChecked"]).length > 1}
          type="link" onClick={(event) => {
            setSource(true);
            form.setFieldsValue({ 'Section_Name': record['Section_Name'], 'Impact_Description': record['Impact_Description'], 'Usecase_Name': record['Usecase_Name'] });
            setOldSectionName(record['Section_Name']);
            setOldUseCaseName(record['Usecase_Name']);
            setOldImpactName(record['Impact_Description']);
            showDrawer()
          }}
            icon={
              <EditOutlined style={{ color: 'green' }} />
            } />
          <Popconfirm
            title="Are you sure you want to delete this?"
            onConfirm={() => handleDelete(record)}
            onCancel={() => handleCancel(record)}
            ref={deleteRef}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            okText="Yes"
            cancelText="No"
          >
            <Button 
            disabled={data.filter((val) => val["isChecked"]).length > 1 && !record.isChecked}
            type="link" icon={<CloseOutlined style={{ color: 'red' }} />} />
          </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  const insertrecord = (value: any) => {
    setProgress(50);
    fetch(`${API_SERVER}impact/add/`,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: "cors",
        body: JSON.stringify(value)
      })
      .then((res) => {
        fetch(`${API_SERVER}impact/find`,
          {
            method: "GET",
            mode: "cors"
          })
          .then((response) => response.json())
          .then((apiData) => {
            setData(apiData);
            let val: any = [];
            for (let i = 0; i < apiData.length; i++) {
              val.push({
                sectionname: apiData[i]["Section_Name"],
                description: apiData[i]["Impact_Description"]
              })
            }
            setImpact(val);
            setProgress(100);
          })

      })
  }

  const updaterecord = (value: any) => {
    setProgress(50);
    fetch(`${API_SERVER}impact/update/${oldSectionName}/${oldUseCaseName}/${oldImpactName}`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: "cors",
        body: JSON.stringify(value)
      })
      .then((res) => {
        fetch(`${API_SERVER}impact/find`,
          {
            method: "GET",
            mode: "cors"
          })
          .then((response) => response.json())
          .then((apiData) => {
            setData(apiData);
            let val: any = [];
            for (let i = 0; i < apiData.length; i++) {
              val.push({
                sectionname: apiData[i]["Section_Name"],
                description: apiData[i]["Impact_Description"]
              })
            }
            setImpact(val);
            setProgress(100);
          })

      })
  }

  const handleDelete = (record: DataType) => {
    let check = 0;
    for (let i = 0; i < data.length; i++)
      if (data[i]["isChecked"])
        check += 1;

    if (check === 0) {
      setProgress(50);
      try{
      fetch(`${API_SERVER}impact/delete/${record['Section_Name']}/${record['Usecase_Name']}/${record['Impact_Description']}`, {
        method: 'DELETE',
        mode: 'cors',
      })
        .then((res) => {
          fetch(`${API_SERVER}impact/find`, {
            method: 'GET',
            mode: 'cors',
          })
            .then((response) => response.json())
            .then((apiData) => {
              setData(apiData);
              // add use case updated values for main cockpit page here
              setProgress(100);
            });
        })
        .catch((error) => console.error('Error deleting record:', error));
      }catch(err){
        console.log(err)
      }
    }
    else {
      let count: number = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i]['isChecked']) {
          try{
          fetch(`${API_SERVER}impact/delete/${data[i]['Section_Name']}/${data[i]['Usecase_Name']}/${data[i]['Impact_Description']}`, {
            method: 'DELETE',
            mode: 'cors',
          })
            .then((res) => {
              fetch(`${API_SERVER}impact/find`, {
                method: 'GET',
                mode: 'cors',
              })
                .then((response) => response.json())
                .then((apiData) => {
                  setData(apiData);
                  count += 1;
                });
            })
            .catch((error) => console.error('Error deleting record:', error));
          } catch (err) {
            console.log(err);
          }
          setProgress((count / check) * 100);
        }
      }
    }
      setProgress(100);
  };

  const handleImpact = (value: any) => {
    if (!source) {
      insertrecord(value);
    }
    else {
      updaterecord(value);
    }
    onClose();
  };

  const handleChange = (value: string) => {
    let val:any=[];
    for(let i=0;i<usecasedata.length;i++)
    {
      if(usecasedata[i]['Section_Name']===value && !val.includes({text:usecasedata[i]['Usecase_Name'],value:usecasedata[i]['Usecase_Name']}))
        val.push({text:usecasedata[i]['Usecase_Name'],value:usecasedata[i]['Usecase_Name']})
      setusecasenameDropdown(val);
    }
  };

  const handleCancel = (record: DataType) => {
    let rows: any = document.getElementsByClassName('ant-table-row');
    let updatedData = data.map(item => ({ ...item, isChecked: false }));
 
    for (let i = 0; i < rows.length; i++) {
      rows[i].style.backgroundColor = "white";
    }
 
    setData(updatedData);
  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className="impact">
      <LoadingBar color="white" onLoaderFinished={() => setProgress(0)} />
      <div ref={tableRef}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={{ pageSize: 5 }}
          size="small"
          rowClassName={(record: DataType) =>
            record.isChecked ? 'checked-row' : ''
        }
        />
      </div>
      <div className="sectionbuttons">
        <Button
          className="sectionbutton"
          ref={addRef}
          type="primary"
          onClick={() => { showDrawer(); }}
        >
          Add Impact
        </Button>
      </div>

      <Drawer
        title="ADD IMPACT"
        placement="right"
        onClose={onClose}
        open={open}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
        width={500}
      >
        <Form form={form} onFinish={handleImpact}>
          <p> Section Name</p>
          <Form.Item
            name="Section_Name"
            rules={[
              { required: true, message: "Please enter Section Name!" },
              { whitespace: true, message: "Empty value not allowed!" },
              { max: 100, message: "Section name should not exceed 100" },
              {
                pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()]*$/,
                message:
                  "Section Name should start with a letter and can only contain letters, numbers, and underscores.",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Section Name"
              onChange={handleChange}
              options={sectionnameDropdown}
            />
          </Form.Item>
          <p> Usecase Name</p>
          <Form.Item
            name="Usecase_Name"
            rules={[
              { required: true, message: "Please enter Usecase Name!" },
              { whitespace: true, message: "Empty value not allowed!" },
              { max: 100, message: "Usecase name should not exceed 100" },
              {
                pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()]*$/,
                message:
                  "Usecase Name should start with a letter and can only contain letters, numbers, and underscores.",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Usecase Name"
              options={usecasenameDropdown}
            />
          </Form.Item>
          <p> Impact Description</p>
          <Form.Item
            name="Impact_Description"
            rules={[
              { required: true, message: "Please enter Description!" },
              {
                pattern: /^[^\s].*$/,
                message: "Description should not start with a space!",
              },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message:
                  "Description can contain any characters, including special characters.",
              },
              {
                max: 2000,
                message:"should not exceed more than 2000",
              }
            ]}
          >
            <TextArea placeholder="Enter Description" autoComplete="off" showCount maxLength={2000}/>
          </Form.Item>
          <a
            className="Reset" onClick={onReset} >
            Reset
          </a>

          <Button
            className="Two"
            type="primary"
            htmlType="submit"
            style={{ marginTop: "30vh", alignItems: "center" }}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default ImpactPage;