export const API_SERVER = 'https://ndc-cockpit-dev.azurewebsites.net/'

export const APP_URL = "https://witty-ground-0fe0fba0f.4.azurestaticapps.net/"

// URL used for initiating authorization request
export const authorityUrl: string = "https://login.microsoftonline.com/0b5ac31e-e17a-4021-9d04-550f86320765";

// End point URL for Power BI API
export const powerBiApiUrl: string = "https://api.powerbi.com/";

// Scope for securing access token
export const scopeBase: string[] = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];

// Client Id (Application Id) of the AAD app.
export const clientId: string = "dfc0ba61-b201-4156-8376-649aa3868822";

// Id of the workspace where the report is hosted
export const workspaceId: string = "0d87b3e3-5248-4eba-9b2a-6650b2ed43d6";