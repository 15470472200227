import React, { useEffect, useState } from 'react';
import { Carousel, Card, Row, Col, List, Flex, Popconfirm, Button, Spin, Tooltip } from 'antd';
import './Carditem.css';
import { AlertOutlined, LoadingOutlined, RollbackOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
// import Swiper core and required modules
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { API_SERVER } from '../../Config';
import Swipercard from './Swipercard';

interface DataType {
  User_Name: string;
  User_email: string;
  Section_Name: string;
  Usecase_Name: string; 
}
interface FetchCardProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  defaultRef: any;
  impactRef: any;
  label: any;
  sectiondescription: any;
  impact: any;
  updates: any;
  updateRef: any;
  dashboardRef: any;
  setPage: any;
  showdashboard: boolean;
  setShowDashboard: React.Dispatch<React.SetStateAction<boolean>>;
  titleRef:any;
  refreshRef:any;
  downloadRef:any;
  pbiviewRef:any;
  backRef:any;
  pbidashboardRef:any;
  username: string;
  useremail: string;
  setUsecase: any;
  setDescription: any;
  setLink: any;
  setFullLink: any;
  usecase: any;
  description: any;
  link: any;
  fulllink: any;
  currentupdate: string;
}
const boxStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  borderRadius: 6,
};

  
const FetchCard: React.FC<FetchCardProps> = ({ defaultRef, impactRef, label, sectiondescription, impact, updates, updateRef, setPage, dashboardRef, showdashboard,setShowDashboard,titleRef,refreshRef,downloadRef, pbiviewRef,backRef,pbidashboardRef, username, useremail, setUsecase, setDescription, setLink, setFullLink, usecase, description, link, fulllink }) => {
  const [currentimpact, setCurrentImpact] = useState([]);
  const [currentupdate, setCurrentupdate] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleViewClick = (usecaseName: string, description: string, link: string) => {
    // navigate(`/${sectionName}/${usecaseName}`);
    setUsecase(usecaseName);
    setDescription(description);
    setLink(link.split('/')[6]);
    setFullLink(link);

    setShowDashboard(true);
    setPage("dashboard");
  };


  useEffect(() => { 
    fetchdashboard();
    setloaded(false);
    return ()=>{setShowDashboard(false);}
   }, []);

  useEffect(() => {
    //filter impact based on the section name
    setPage("usecase");
    let val: any = [];
    try {
      for (let i = 0; i < impact.length; i++) {
        if (impact[i]['sectionname'] === label) {
          val.push(impact[i]['description']);
        }
      }
    } catch (err) { }
    setCurrentImpact(val);
    //filter updates based on the section name
    let updatediv: any = [];
    try {
      for (let i = 0; i < updates.length; i++) {
        if (updates[i]['sectionname'] === label) {
          updatediv.push(updates[i]['description']);
        }
      }
    } catch (err) { }
    setCurrentupdate(updatediv);
  }, [impact, label, setPage, updates]);

  const handleDelete = async (sectionName: any, usecaseName: any) => {
    setloaded(false);
    let value: any = {};
    value['Section_Name']=sectionName;
    value['Usecase_Name']=usecaseName;
    value['User_Name']=username;
    value['User_email']=useremail;
    
    try {
      const response = await fetch(
        `${API_SERVER}preference/add`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: "cors",
          body: JSON.stringify(value)
        }
      )
        .then((res) => {
          fetchdashboard();
        })
        .catch((error) => console.error("Error deleting dashboard:", error));
    } catch (err) {
      console.log(err);
    }
  };

  //Fetching dashboard
  const fetchdashboard = () => {
    try {
      fetch(`${API_SERVER}usecase/find`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => response.json())
        .then((apiData: any) => {
          try {
            fetch(`${API_SERVER}preference/find`, {
              method: "GET",
              mode: "cors",
            })
              .then((response) => response.json())
              .then((apiDatapreference: any) => {
                if(apiDatapreference.length===0){
                  setloaded(true);
                  setDashboard(apiData);
                }
                else{
                  let val=[];
                  for(let i=0;i<apiDatapreference.length;i++)
                    if(apiDatapreference[i]['User_Name']===username && apiDatapreference[i]['User_email']===useremail)
                      val.push(apiDatapreference[i]);
                  let valdb: any = [];
                  for(let i=0;i<apiData.length;i++)
                  {
                    let present=0;
                    for(let j=0;j<val.length;j++)
                      if(val[j]['Section_Name']===apiData[i]['Section_Name'] && apiData[i]['Usecase_Name']===val[j]['Usecase_Name'])
                      {
                        present=1;
                        break;
                      }
                    if(present===0)
                      valdb.push(apiData[i]);
                  }
                  setDashboard(valdb);
                  setData(val);
                  setloaded(true);
                }
                
              })
              .catch((error) => {
                console.error("Error fetching preference:", error);
              });
          } catch (err) {
            console.log(err);
          }
        })
        .catch((error) => {
          console.error("Error fetching usecases:", error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {!showdashboard ? (
        <div className="container my-3">
          <div className="default-card-wrapper">
            <Row gutter={[10, 10]}>
              <Col span={20} key={0}>
                <Flex
                  vertical={true}
                  style={boxStyle}
                  justify="space-between"
                  align="center"
                >
                  <Card
                    className="card1"
                    ref={defaultRef}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="card1-label">
                      <span>{label}</span>
                    </div>
                    <div className="card1-desc">
                      <span
                        title={
                          sectiondescription.length > 230
                            ? sectiondescription
                            : undefined
                        }
                      >
                        {sectiondescription.length > 230
                          ? sectiondescription.substring(0, 230) + "...."
                          : sectiondescription}
                      </span>
                    </div>
                  </Card>
                  {dashboard && loaded && (
                    <Swiper
                      className="swiperslide"
                      effect={"coverflow"}
                      grabCursor={true}
                      rewind={true}
                      centeredSlides={true}
                      slidesPerView={2}
                      coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 0,
                        modifier: 1,
                        slideShadows: true,
                      }}
                      navigation={true}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[EffectCoverflow, Pagination, Navigation]}
                      spaceBetween={20}
                      style={{
                        borderRadius: "1vh",
                        padding: "1vh",
                        fontSize: "4.5vh",
                        position: "absolute",
                        top: "35vh",
                        left: "1.2vw",
                        height: "35vh",
                        width: "70vw",
                        zIndex: "5",
                        fontFamily: "AB Gilroy",
                      }}
                      ref={dashboardRef}
                    >
                      {dashboard.map((val: any, index) => {
                        return val["Section_Name"] === label ? (
                          <SwiperSlide
                            style={{
                              background:
                                "linear-gradient(94deg, #461E96, #691794, #821392)",
                              borderRadius: "1vh",
                              padding: "2vh",
                              color: "white",
                              height: "90%",
                            }}
                          >
                            <Popconfirm
                              title="Are you sure you want to delete this?"
                              onConfirm={() =>
                                handleDelete(
                                  val["Section_Name"],
                                  val["Usecase_Name"]
                                )
                              }
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: "red" }}
                                />
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                className="card-btn"
                                type="link"
                                icon={
                                  <CloseOutlined
                                    style={{
                                      color: "white",
                                      fontSize: "2vh",
                                      fontWeight: "bold",
                                    }}
                                  />
                                }
                                style={{
                                  position: "fixed",
                                  top: "10px",
                                  right: "10px",
                                }}
                              />
                            </Popconfirm>
                            <h1
                              style={{
                                fontSize: "3.5vh",
                                paddingLeft: "1.5vw",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              title={
                                val["Usecase_Name"].length > 30
                                  ? val["Usecase_Name"]
                                  : undefined
                              }
                            >
                              {val["Usecase_Name"]}
                            </h1>

                            <p
                              style={{
                                fontSize: "2vh",
                                lineHeight: "2.3vh",
                                paddingLeft: "1.5vw",
                                textOverflow: "ellipsis",
                                maxHeight: "13.8vh",
                                overflow: "hidden",
                              }}
                              title={
                                val["Description"].length > 240
                                  ? val["Description"]
                                  : undefined
                              }
                            >
                              {val["Description"].length > 240
                                ? val["Description"].substring(0, 240) + "...."
                                : val["Description"]}
                            </p>

                            <Button
                              className="view"
                              type="primary"
                              id={`usecase${index}`}
                              onClick={() =>
                                handleViewClick(
                                  val["Usecase_Name"],
                                  val["Description"],
                                  val["Link"]
                                )
                              }
                            >
                              View
                            </Button>
                          </SwiperSlide>
                        ) : null;
                      })}
                    </Swiper>
                  )}
                  {!loaded && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          className="loader"
                          style={{ fontSize: 64 }}
                          spin
                        />
                      }
                    />
                  )}
                  <div className="updates-box">
                    <Card
                      className="card3"
                      title={
                        <span
                          style={{
                            fontSize: "2.5vh",
                            textAlign: "left",
                            fontWeight: "400",
                          }}
                        >
                          {<AlertOutlined style={{ color: "White" }} />} Updates
                        </span>
                      }
                      ref={updateRef}
                    >
                      <div
                        className="newupdate"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Carousel vertical autoplay dots={false}>
                          {currentupdate.map((item: string, index: number) => (
                            <div className="flex-container" key={index}>
                              <span
                                className="firstupdate"
                                title={item.length > 130 ? item : undefined}
                              >
                                {item.length > 130
                                  ? item.substring(0, 130) + "...."
                                  : item}
                              </span>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </Card>
                  </div>
                </Flex>
              </Col>
              <Col flex="0 0 17vw" span={4} key={1}>
                <Card
                  className="card2"
                  title={"Impact"}
                  style={{
                    fontSize: "2.5vh !important",
                    height: "87.8vh",
                    borderRadius: "1vh",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    marginTop: "0.3vh",
                    marginRight: "1vh",
                    overflow: "auto !important",
                  }}
                  ref={impactRef}
                >
                  <List
                    className="listitems"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2vh",
                      fontFamily: "AB Gilroy",
                      backgroundColor: "rgba(162, 142, 202, 0.15)",
                      color: "#3B3B3B",
                      borderRadius: "1vh",
                      padding: "0.5vh",
                    }}
                    dataSource={currentimpact}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <Swipercard
          usecase={usecase}
          description={description}
          link={link}
          fulllink={fulllink}
          titleRef={titleRef}
          refreshRef={refreshRef}
          pbiviewRef={pbiviewRef}
          downloadRef={downloadRef}
          pbidashboardRef={pbidashboardRef}
          config={undefined}
        />
      )}

      {showdashboard && (
        <Tooltip title="Go back" placement="bottomRight">
          <Button
            id="gobacktomain"
            ref={backRef}
            className="goback"
            type="link"
            icon={<RollbackOutlined color={"black"} />}
            onClick={() => {
              setShowDashboard(false);
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default FetchCard;