import React from 'react'
import logo from "./fatal-error-3.svg";
import  './LoginError.css';

export default function LoginError() {
    return (
        <div className='errorimage'>
            <main>
                <div>
                    <div >
                        <img src={logo} alt="" />

                    </div>

                    <div className='errortext'>
                        <span>Oops!  Login&nbsp;error</span>
                        <span>
                            <div>
                                <b>Please&nbsp;refresh and login again...</b>
                            </div>
                        </span>
                    </div>
                </div>
            </main>

        </div>
    )
}
