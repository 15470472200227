import { useEffect } from 'react';
import { Button, message } from 'antd';
import './LandingPage.css';
import { Link } from 'react-router-dom';


const LandingPage = (props: any) => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    // Landing animation
    setTimeout(() => {
      try {
        const sidediv: any = document.getElementsByClassName("InitialSidedivlanding")[0];
        const sidedivoverlay: any = document.getElementsByClassName("InitialSidedivoverlaylanding")[0];

        sidediv.style.width = "40vw";
        sidedivoverlay.style.width = "40vw";
        if (props.isVisited == false) {
          sidediv.style.transition = "width 3s";
          sidedivoverlay.style.transition = "width 3s";
          props.setIsVisited(true)

        } else {

          sidediv.style.transition = "width 0s";
          sidedivoverlay.style.transition = "width 0s";
        }
      } catch {

      }


    }, 1000);

    // Landing animation for button
    if (!props.isVisited)
      setTimeout(() => {
        const usecase: any = document.getElementsByClassName("viewusecaselanding")[0];
        const simulate: any = document.getElementsByClassName("gotosimulatelanding")[0];
        usecase.style.display = "block";
        simulate.style.display = "block";
      }, 3000);
    else {
      const usecase: any = document.getElementsByClassName("viewusecaselanding")[0];
      const simulate: any = document.getElementsByClassName("gotosimulatelanding")[0];
      usecase.style.display = "block";
      simulate.style.display = "block";
    }
    // button hover animation
    const usecase: any = document.getElementsByClassName("viewusecaselanding")[0];

    //manually firing mouseover and mouseout event
    const mouseoverEvent = new Event('mouseover');
    const mouseoutEvent = new Event('mouseout');
    usecase.dispatchEvent(mouseoverEvent);
    usecase.dispatchEvent(mouseoutEvent);
    usecase.addEventListener("mouseover", () => {
      const img: any = document.getElementsByClassName("usecaseimagelanding")[0];
      img.style.backgroundSize = "180%";
    });
    usecase.addEventListener("mouseout", () => {
      const img: any = document.getElementsByClassName("usecaseimagelanding")[0];
      img.style.backgroundSize = "150%";
    });

    // button hover animation
    const simulate: any = document.getElementsByClassName("gotosimulatelanding")[0];
    //manually firing mouseover and mouseout event
    simulate.dispatchEvent(mouseoverEvent);
    simulate.dispatchEvent(mouseoutEvent);
    simulate.addEventListener("mouseover", () => {
      const img: any = document.getElementsByClassName("simulateimagelanding")[0];
      img.style.backgroundSize = "180%";
    });
    simulate.addEventListener("mouseout", () => {
      const img: any = document.getElementsByClassName("simulateimagelanding")[0];
      img.style.backgroundSize = "150%";
    });
  }, []);

  // For simulate
  const error = () => {
    messageApi.open({
      className: "messagelanding",
      type: 'warning',
      content: 'Will be available soon!',
    });
  };

  
  return (
    <>
      {contextHolder}
      <div className='landinglanding'>
        <div className={props.isVisited ? 'sidedivlanding' : 'InitialSidedivlanding'}>
        </div>
        <div className={props.isVisited ? 'sidedivoverlaylanding' : 'InitialSidedivoverlaylanding'}>
          <div className='welcomelanding'>Welcome to</div>
          <div className='ndccockpitlanding'>NDC Cockpit</div>
        </div>
        <div className='rightdivlanding'>
          <div className='usecaselanding'>
            <div className='usecaseimagelanding'>
            </div>
            <div className='usecasetextlanding'>
              View different sections and usecases solved within NDC.
              This section also inlcudes impacts and latest updates from  sections and usecase
              <Button type="primary" className='viewusecaselanding'><Link to={props.menuItems && props.menuItems.length > 0 ? props.menuItems[0]["path"] : "/"}>View Usecases</Link></Button>
            </div>
          </div>
          <div className='simulatelanding'>
            <div className='simulateimagelanding'>
            </div>
            <div className='simulatetextlanding'>
              Improves resource allocation, process flow, and overall performance at the NDC with the help of this dynamic simulation view.
              <Button type="primary" className='gotosimulatelanding' onClick={error}>Simulate</Button>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default LandingPage