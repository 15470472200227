import React, { useState, useEffect } from 'react';
import { Table, Space, Select } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { Button, Drawer, Popconfirm, Form, Checkbox } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import LoadingBar from 'react-top-loading-bar';
import './SettingsPage.css';
import { API_SERVER } from '../../Config';


interface DataType {
  User_Name: string;
  User_email: string;
  Section_Name: string;
  Usecase_Name: string;
  isChecked: boolean;
  
}
interface PrefPageProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setMenuItems: any;
  setPage: any;
  tableRef: any;
  deleteRef: any;
  addRef: any;
  multiselectRef: any;
  username: string;
  useremail: string;
}

const PreferencePage: React.FC<PrefPageProps> = ({ setProgress, setPage,tableRef, deleteRef,addRef,multiselectRef,username, useremail }) => {
  const [data, setData] = useState<DataType[]>([]);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [sectionnameFilters, setSectionnameFilters] = useState<Array<{ text: string; value: string }>>([]);
  const [usecasenameFilters, setUsecasenameFilters] = useState<Array<{ text: string; value: string }>>([]);
  const [usecasedata, setusecasedata] = useState([]);
  const [sectionnameDropdown, setsectionnameDropdown] = useState<Array<{ text: string, value: string }>>([]);
  const [usecasenameDropdown, setusecasenameDropdown] = useState<Array<{ text: string, value: string }>>([]);

  function getusecaseandsection() {
    try {
      fetch(`${API_SERVER}usecase/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData) => {
          setusecasedata(apiData)
          let usecase: Array<{ text: string, value: string }> = [];
          let section: Array<{ text: string, value: string }> = [];
          let usecasecheck: Array<string> = [];
          let sectioncheck: Array<string> = [];
          for (let i = 0; i < apiData.length; i++) {
            if (!usecasecheck.includes(apiData[i]['Usecase_Name'])) {
              usecase.push({ text: apiData[i]['Usecase_Name'], value: apiData[i]['Usecase_Name'] });
              usecasecheck.push(apiData[i]['Usecase_Name']);
            }

            if (!sectioncheck.includes(apiData[i]['Section_Name'])) {
              section.push({ text: apiData[i]['Section_Name'], value: apiData[i]['Section_Name'] });
              sectioncheck.push(apiData[i]['Section_Name']);
            }
          }
          setsectionnameDropdown(section);
          setusecasenameDropdown(usecase);
        })
    }
    catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    setPage("settings/preference");
    setProgress(50);
    try {
      fetch(`${API_SERVER}preference/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: DataType[]) => {
          getusecaseandsection();
          for (let i = 0; i < apiData.length; i++)
            apiData[i]['isChecked'] = false;
          let val=[];
          for(let i=0;i<apiData.length;i++)
            if(apiData[i]['User_Name']===username && apiData[i]['User_email']===useremail)
              val.push(apiData[i]);
          setData(val);
          const uniqueSectionNames = Array.from(new Set(val.map((item: DataType) => item.Section_Name)));
          const dynamicSectionNameFilters: { text: string; value: string }[] = uniqueSectionNames.map((sectionName: string) => ({ text: sectionName, value: sectionName }));
          setSectionnameFilters(dynamicSectionNameFilters);

          const uniqueUsecaseNames: string[] = Array.from(new Set(val.map((item: DataType) => item.Usecase_Name)));
          const dynamicUsecaseNameFilters: { text: string; value: string }[] = uniqueUsecaseNames.map((usecaseName: string) => ({ text: usecaseName, value: usecaseName }));
          setUsecasenameFilters(dynamicUsecaseNameFilters);
          setProgress(100);
          let rows: any = document.getElementsByClassName('ant-table-row');
          for (let i = 0; i < rows.length; i++) {
            rows[i].addEventListener('click', (event: any) => {
              try {
                let nodes = event.target.parentNode;
                nodes.childNodes[nodes.childNodes.length - 1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].click();
              }
              catch (err) { }
            })
          }

        })

    }
    catch (err) {
      console.log(err);
    }

  }, []);

  const handlecheckbox = (e: any) => {
    const { value, checked } = e.target;
    setData(prevData =>
      prevData.map(item =>
        item.Section_Name === value["Section_Name"] &&
        item.Usecase_Name === value["UseCase_Name"]
          ? { ...item, isChecked: checked }
          : item
      )
    );
  };
  

  const handleCancel = (record: DataType) => {
    let rows: any = document.getElementsByClassName('ant-table-row');
    let updatedData = data.map(item => ({ ...item, isChecked: false }));
 
    for (let i = 0; i < rows.length; i++) {
      rows[i].style.backgroundColor = "white";
    }
 
    setData(updatedData);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Section Name',
      dataIndex: 'Section_Name',
      key: 'Section_Name',
      filters: sectionnameFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Section_Name === value,
      sorter: (a, b) => a.Section_Name.localeCompare(b.Section_Name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Usecase Name',
      dataIndex: 'Usecase_Name',
      key: 'Usecase_Name',
      filters: usecasenameFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Usecase_Name === value,
      sorter: (a, b) => a.Usecase_Name.localeCompare(b.Usecase_Name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,

    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <span ref={multiselectRef}>
          <Checkbox
              checked={data.filter((val) => val["Section_Name"] === record["Section_Name"] && val["Usecase_Name"] === record["Usecase_Name"])[0]["isChecked"]}
              value={{ "Section_Name": record.Section_Name, "UseCase_Name": record.Usecase_Name }} onChange={handlecheckbox}
            ></Checkbox>
            </span>

          {record.Section_Name && (
            <Popconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => handleDelete(record)}
              onCancel={() => handleCancel(record)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText="Yes"
              cancelText="No"
            >
              <Button ref={deleteRef}
              disabled={data.filter((val) => val["isChecked"]).length > 1 && !record.isChecked}
              type="link" icon={<CloseOutlined style={{ color: 'red' }} />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // form.resetFields();
  };
  const handlePref = (value: any) => {
    setProgress(50);
    value['User_Name']=username;
    value['User_email']=useremail;
    try {
      fetch(`${API_SERVER}preference/add/`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: "cors",
          body: JSON.stringify(value)
        })
        .then((res) => {
          fetch(`${API_SERVER}preference/find`,
            {
              method: "GET",
              mode: "cors",
            })
            .then((response) => response.json())
            .then((apiData) => {
              let val=[];
              for(let i=0;i<apiData.length;i++)
                if(apiData[i]['User_Name']===username && apiData[i]['User_email']===useremail)
                  val.push(apiData[i]);
              setData(val);
              setProgress(100);
            })

        })
      onClose();

    }
    catch (err) {
      console.log(err);
    }
  };

  const handleDelete = (record: DataType) => {
    let check = 0;
    for (let i = 0; i < data.length; i++)
      if (data[i]["isChecked"])
        check += 1;

    if (check === 0) {
      setProgress(50);
      try {
        fetch(`${API_SERVER}preference/delete/${record['Section_Name']}/${record['Usecase_Name']}/${username}/${useremail}`, {
          method: 'DELETE',
          mode: 'cors',
        })
          .then((res) => {
            fetch(`${API_SERVER}preference/find`, {
              method: 'GET',
              mode: 'cors',
            })
              .then((response) => response.json())
              .then((apiData) => {
                let val=[];
                for(let i=0;i<apiData.length;i++)
                  if(apiData[i]['User_Name']===username && apiData[i]['User_email']===useremail)
                    val.push(apiData[i]);
                setData(val);
                // add use case updated values for main cockpit page here
                setProgress(100);
              });
          })
          .catch((error) => console.error('Error deleting record:', error));

      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      let count: number = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i]['isChecked']) {
          try {
            fetch(`${API_SERVER}preference/delete/${data[i]['Section_Name']}/${data[i]['Usecase_Name']}/${username}/${useremail}`, {
              method: 'DELETE',
              mode: 'cors',
            })
              .then((res) => {
                fetch(`${API_SERVER}preference/find`, {
                  method: 'GET',
                  mode: 'cors',
                })
                  .then((response) => response.json())
                  .then((apiData) => {
                    let val=[];
                    for(let i=0;i<apiData.length;i++)
                      if(apiData[i]['User_Name']==username && apiData[i]['User_email']==useremail)
                        val.push(apiData[i]);
                    setData(val);
                    count += 1;
                    // add use case updated values for main cockpit page here
                  });
              })
              .catch((error) => console.error('Error deleting record:', error));
            setProgress((count / check) * 100);

          }
          catch (err) {
            console.log(err);
          }
        }
      }
    }
    setProgress(100);
  };
  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };
  const onReset = () => {
    form.resetFields();
  };

  

  const handleChange = (value: string) => {
    let val: any = [];
    for (let i = 0; i < usecasedata.length; i++) {
      if (usecasedata[i]['Section_Name'] === value && !val.includes({ text: usecasedata[i]['Usecase_Name'], value: usecasedata[i]['Usecase_Name'] }))
        val.push({ text: usecasedata[i]['Usecase_Name'], value: usecasedata[i]['Usecase_Name'] })
      setusecasenameDropdown(val);
    }
  };

  return (
    <div className='preference'>
      <LoadingBar color='white' onLoaderFinished={() => setProgress(0)} />
      <div ref={tableRef}>
      <Table columns={columns} dataSource={data} bordered onChange={onChange} pagination={{ pageSize: 5 }} size='small' 
      rowClassName={(record: DataType) =>
        record.isChecked ? 'checked-row' : ''
    }/>
      </div>
      <div className='preferencebuttons'>
      <Button className="preferencebutton" ref={addRef} type="primary" onClick={() => { showDrawer(); onReset(); }}>
        Add Preference
      </Button>
      </div>
      
      <Drawer title='PREFERENCE' placement="right" onClose={onClose} open={open} closeIcon={<CloseOutlined style={{ color: 'white' }} />} width={500}>
        <Form form={form} onFinish={handlePref}>
          <p> Section Name</p>
          <Form.Item name="Section_Name"
            rules={[{ required: true, message: 'Please enter Section Name!' },
            { whitespace: true, message: "Empty value not allowed!" },
            { max: 100, message: "Section name should not exceed 100" },
            { pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/, message: 'Section Name should start with a letter and can only contain letters, numbers, and underscores.', }
            ]}>
            <Select
              style={{ width: '100%' }}
              placeholder="Enter Section Name"
              onChange={handleChange}
              options={sectionnameDropdown}
            />
          </Form.Item>
          <p> Usecase Name</p>
          <Form.Item name="Usecase_Name"
            rules={[{ required: true, message: 'Please enter Usecase Name!' },
            { whitespace: true, message: "Empty value not allowed!" },
            { max: 100, message: "Usecase name should not exceed 100" },
            { pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/, message: 'Usecase Name should start with a letter and can only contain letters, numbers, and underscores.', }
            ]}>
            <Select
              style={{ width: '100%' }}
              placeholder="Enter Usecase Name"
              // onChange={handleChange}
              options={usecasenameDropdown}
            />
          </Form.Item>
          <a
            className="Reset" onClick={onReset} >
            Reset
          </a>
          <Button className='Two' type="primary" htmlType="submit" style={{ marginTop: '20vh', alignItems: 'center' }}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default PreferencePage;