import React, { useState, useEffect } from 'react';
import { Table, Space, Checkbox } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { Button, Drawer, Input, Popconfirm, Form } from 'antd';
import { EditOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import LoadingBar from 'react-top-loading-bar';
import TextArea from 'antd/es/input/TextArea';
import './SettingsPage.css';
import { API_SERVER } from '../../Config';

interface DataType {
  Impact_Description: any;
  Section_Name: string;
  Description: string;
  isChecked: boolean;
  key: React.ReactNode;

}
interface SectionPageProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setMenuItems: any;
  setPage: any;
  tableRef: any;
  editRef: any;
  deleteRef: any;
  addRef: any;
  multiselectRef: any;
  usecaseauto: any;
  setUsecaseauto: any;
  fetchdashboard: any;
}

const SectionPage: React.FC<SectionPageProps> = ({ setProgress, setMenuItems, setPage, tableRef, editRef, deleteRef, addRef, multiselectRef, usecaseauto, setUsecaseauto, fetchdashboard }) => {
  const [oldSectionName, setOldSectionName] = useState<string>("")
  const [data, setData] = useState<DataType[]>([]);
  const [sectionnameFilters, setSectionnameFilters] = useState<Array<{ text: string, value: string }>>([]);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    if(source){
      onReset();
    }
    setSource(false);
    
    // form.resetFields();
  };

  useEffect(() => {
    setPage("settings");
    setProgress(50);
    try {
      fetch(`${API_SERVER}section/find`, {
        method: "GET",
        mode: "cors"
      })
        .then((response) => response.json())
        .then((apiData: DataType[]) => {
          for (let i = 0; i < apiData.length; i++)
            apiData[i]['isChecked'] = false;
          setData(apiData);
          const uniqueSectionNames = Array.from(new Set(apiData.map((item: DataType) => item.Section_Name)));
          const dynamicSectionNamesFilters: { text: string; value: string }[] = uniqueSectionNames.map(Section_Name => ({ text: Section_Name, value: Section_Name }));
          setSectionnameFilters(dynamicSectionNamesFilters);
          setProgress(100);
          let rows: any = document.getElementsByClassName('ant-table-row');
          for (let i = 0; i < rows.length; i++) {
            rows[i].addEventListener('click', (event: any) => {
              try {
                let nodes = event.target.parentNode;
                nodes.childNodes[nodes.childNodes.length - 1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].click();
              }
              catch (err) { }
            })
          }

        })
        .catch((error) => console.error('Error fetching data:', error));
    }
    catch (err) {
      console.log(err);
    }

  }, []);


  const handlecheckbox = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    setData(prevData =>
      prevData.map(item =>
        item.Section_Name === value["Section_Name"] &&
        item.Impact_Description === value["Impact_Description"]
          ? { ...item, isChecked: e.target.checked }
          : item
      )
    );
  };
  
  const handleCancel = (record: DataType) => {
    let rows: any = document.getElementsByClassName('ant-table-row');
    let updatedData = data.map(item => ({ ...item, isChecked: false }));
 
    for (let i = 0; i < rows.length; i++) {
      rows[i].style.backgroundColor = "white";
    }
 
    setData(updatedData);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Section Name",
      dataIndex: 'Section_Name',
      key: 'Section_Name',
      filters: sectionnameFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Section_Name === value,
      sorter: (a, b) => a.Section_Name.localeCompare(b.Section_Name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      width: "50%",
      sorter: (a, b) => a.Description.localeCompare(b.Description),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '5vh',
      render: (_, record) => (
        <Space size="middle">
          <span ref={multiselectRef}>
            <Checkbox
              checked={data.filter((val) => val["Section_Name"] === record["Section_Name"] && val["Description"] === record["Description"])[0]["isChecked"]}
              value={{ "Section_Name": record.Section_Name, "Description": record.Description }} onChange={handlecheckbox}
            ></Checkbox>
          </span>
          <span ref={editRef}
          >
            <Button type="link"
              disabled={data.filter((val) => val["isChecked"]).length > 1}
              onClick={(event) => {
                setSource(true);
                form.setFieldsValue({ 'Section_Name': record['Section_Name'], 'Description': record['Description'] });
                setOldSectionName(record.Section_Name)
                showDrawer()
              }}
              icon={
                <EditOutlined style={{ color: 'green' }} />
              } />

            <Popconfirm
              title="Are you sure you want to delete this?"
              onConfirm={() => handleDelete(record)}
              ref={deleteRef}
              onCancel={() => handleCancel(record)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={(!(record['isChecked'] === true && data.filter(x => x.isChecked === true).length > 0)) === true && data.filter(x => x.isChecked === true).length > 1}
                type="link"
                icon={<CloseOutlined style={{ color: 'red' }} />} />
            </Popconfirm>

          </span>
        </Space>
      ),
    },
  ];

  const insertrecord = (value: any) => {
    setProgress(50);
    try {
      fetch(`${API_SERVER}section/add/`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: "cors",
          body: JSON.stringify(value)
        })
        .then((res) => {
          fetch(`${API_SERVER}section/find`,
            {
              method: "GET",
              mode: "cors"
            })
            .then((response) => response.json())
            .then((apiData) => {
              setData(apiData);
              let val: any = [];
              for (let i = 0; i < apiData.length; i++) {
                val.push({
                  label: apiData[i]["Section_Name"],
                  key: (i + 1).toString(),
                  path: "/" + apiData[i]["Section_Name"].toLowerCase().replaceAll(" ", "-"),
                  description: apiData[i]['Description']
                })
              }
              setMenuItems(val);
              fetchdashboard();
              setProgress(100);
            })
        })
    }
    catch (err) {
      console.log(err);
    }

  }

  const updaterecord = (value: any) => {
    setProgress(50);
    try {
      fetch(`${API_SERVER}section/update/${oldSectionName}`,
        {
          method: "PUT",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: "cors",
          body: JSON.stringify(value)
        })
        .then((res) => {
          fetch(`${API_SERVER}section/find`,
            {
              method: "GET",
              mode: "cors"
            })
            .then((response) => response.json())
            .then((apiData) => {
              setData(apiData);
              let val: any = [];
              for (let i = 0; i < apiData.length; i++) {
                val.push({
                  label: apiData[i]["Section_Name"],
                  key: (i + 1).toString(),
                  path: "/" + apiData[i]["Section_Name"].toLowerCase().replaceAll(" ", "-"),
                  description: apiData[i]['Description']
                })
              }
              fetchdashboard();
              setMenuItems(val);
              setProgress(100);
            })

        })
    }
    catch (err) {
      console.log(err);
    }

  }

  const handleSection = (value: any) => {
    if (!source) {
      insertrecord(value);
    }
    else {
      updaterecord(value);
    }
    onClose();
  };

  
  const handleDelete = (record: DataType) => {
    let check = 0;
    for (let i = 0; i < data.length; i++)
      if (data[i]["isChecked"])
        check += 1;

    if (check === 0) {
      setProgress(50);
      try {
        fetch(`${API_SERVER}section/delete/${record['Section_Name']}`, {
          method: 'DELETE',
          mode: 'cors',
        })
          .then((res) => {
            fetch(`${API_SERVER}section/find`, {
              method: 'GET',
              mode: 'cors',
            })
              .then((response) => response.json())
              .then((apiData) => {
                setData(apiData);
                let val: any = [];
                for (let i = 0; i < apiData.length; i++) {
                  val.push({
                    label: apiData[i]["Section_Name"],
                    key: (i + 1).toString(),
                    path: "/" + apiData[i]["Section_Name"].toLowerCase().replaceAll(" ", "-"),
                    description: apiData[i]['Description']
                  })
                }
                setMenuItems(val);
                fetchdashboard();
                setProgress(100);
              });
          })
          .catch((error) => console.error('Error deleting record:', error));
      }
      catch (err) {
        console.log(err);
      }

    }
    else {
      let count: number = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i]['isChecked']) {
          try {
            fetch(`${API_SERVER}section/delete/${data[i]['Section_Name']}`, {
              method: 'DELETE',
              mode: 'cors',
            })
              .then((res) => {
                fetch(`${API_SERVER}section/find`, {
                  method: 'GET',
                  mode: 'cors',
                })
                  .then((response) => response.json())
                  .then((apiData) => {
                    setData(apiData);
                    count += 1;
                    let val: any = [];
                    for (let i = 0; i < apiData.length; i++) {
                      val.push({
                        label: apiData[i]["Section_Name"],
                        key: (i + 1).toString(),
                        path: "/" + apiData[i]["Section_Name"].toLowerCase().replaceAll(" ", "-"),
                        description: apiData[i]['Description']
                      })
                    }
                    fetchdashboard();
                    setMenuItems(val);

                  });
              })
              .catch((error) => console.error('Error deleting record:', error));
            setProgress((count / check) * 100);
          }
          catch (err) {
            console.log(err);
          }

        }
      }
      setProgress(100);
    }

  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='section'>
      <LoadingBar color='white' onLoaderFinished={() => setProgress(0)} />
      <div ref={tableRef}>
        <Table columns={columns}
          dataSource={data} bordered onChange={onChange} pagination={{ pageSize: 5 }} size='middle'
          rowClassName={(record: DataType) =>
            record.isChecked ? 'checked-row' : ''
          } />
      </div>
      <div className='sectionbuttons'>
      <Button className="sectionbutton" ref={addRef} type="primary" onClick={() => { showDrawer(); }}>
       Add Section
      </Button>

      </div>
      
      <Drawer title="ADD SECTION" placement="right" onClose={onClose} open={open} closeIcon={<CloseOutlined style={{ color: 'white' }} />} width={500}>
        <Form form={form} onFinish={handleSection}>
          <p>Section Name</p>
          <Form.Item name="Section_Name"
            rules={[{ required: true, message: 'Please enter Section Name!' },
            { whitespace: true, message: "Empty value not allowed!" },
            { pattern: /^[a-z A-Z][a-zA-Z0-9_ &!@#*$(),.-]*$/, message: 'Section Name should start with a letter and can only contain letters, numbers, and underscores.', },
            {
              max: 2000,
              message:"should not exceed more than 2000",
            }
            
            ]} >
            <Input placeholder="Enter Section Name" autoComplete='off' />
          </Form.Item>
          <p>Description</p>
          <Form.Item
            name="Description"
            rules={[
              { required: true, message: "Please enter Description!" },
              {
                pattern: /^[^\s].*$/,
                message: "Description should not start with a space!",
              },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message:
                  "Description can contain any characters, including special characters.",
              },
              {
                max: 2000,
                message:"should not exceed more than 20",
              }
            ]}
          >
            <TextArea placeholder="Enter Description" autoComplete="off" showCount maxLength={2000}/>
          </Form.Item>
          <a
            className="Reset" onClick={onReset} >
            Reset
          </a>
          <Button className='Two' type="primary" htmlType="submit" style={{ marginTop: '40vh', alignItems: 'center' }}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>

  );
};

export default SectionPage;