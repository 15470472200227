import React, { useState, useEffect } from 'react';
import { Table, Space, Select, Button, Drawer,  Popconfirm, Form, Checkbox  } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EditOutlined, CloseOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import './SettingsPage.css';
import LoadingBar from 'react-top-loading-bar';
import TextArea from 'antd/es/input/TextArea';
import { API_SERVER } from '../../Config';

interface DataType {
  Section_Name: string;
  Usecase_name: string;
  Description: string;
  isChecked: boolean;
}
interface UpdatesPageProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setUpdates: any;
  setPage: any;
  tableRef: any;
  editRef: any;
  deleteRef: any;
  addRef: any;
  multiselectRef: any;

}

const UpdatesPage: React.FC<UpdatesPageProps> = ({ setProgress, setUpdates, setPage, tableRef, editRef, deleteRef, addRef, multiselectRef}) => {
  const [data, setData] = useState<DataType[]>([]);
  const [sectionnameFilters, setSectionnameFilters] = useState<Array<{ text: string, value: string }>>([]);
  const [usecasenameFilters, setUsecasenameFilters] = useState<Array<{ text: string, value: string }>>([]);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState(false);
  const [form] = Form.useForm();
  const [oldSectionName, setOldSectionName] = useState<string>("");
  const [oldUseCaseName, setOldUseCaseName] = useState<string>("");
  const [oldUpdateName, setOldUpdateName] = useState<string>("");
  const [usecasedata, setusecasedata] = useState([]);
  const [sectionnameDropdown, setsectionnameDropdown] = useState<Array<{ text: string, value: string }>>([]);
  const [usecasenameDropdown, setusecasenameDropdown] = useState<Array<{ text: string, value: string }>>([]);
  
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    if(source){
      onReset();
    }
    setSource(false);
    // form.resetFields();
  };

  function getusecaseandsection(){
    fetch(`${API_SERVER}usecase/find`, {
      method: "GET",
      mode: "cors"
    })
      .then((response) => response.json())
      .then((apiData) => {
        setusecasedata(apiData)
        let usecase:Array<{ text: string, value: string }>=[];
        let section:Array<{ text: string, value: string }>=[];
        let usecasecheck:Array<string>=[];
        let sectioncheck:Array<string>=[];
        for(let i=0;i<apiData.length;i++)
        {
          if(!usecasecheck.includes(apiData[i]['Usecase_Name']))
          {
            usecase.push({text:apiData[i]['Usecase_Name'],value:apiData[i]['Usecase_Name']});
            usecasecheck.push(apiData[i]['Usecase_Name']);
          }
            
          if(!sectioncheck.includes(apiData[i]['Section_Name']))
          {
            section.push({text:apiData[i]['Section_Name'],value:apiData[i]['Section_Name']});
            sectioncheck.push(apiData[i]['Section_Name']);
          }
        }
        let rows: any = document.getElementsByClassName('ant-table-row');
        for (let i = 0; i < rows.length; i++) {
          rows[i].addEventListener('click', (event: any) => {
            try {
              let nodes = event.target.parentNode;
              nodes.childNodes[nodes.childNodes.length - 1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0].click();
            }
            catch (err) { }
          })
        }
        setsectionnameDropdown(section);
        setusecasenameDropdown(usecase);
    })
  }

  useEffect(() => {
    setPage("settings")
    getusecaseandsection();
    setProgress(50);
    fetch(`${API_SERVER}updates/find`, {
      method: "GET",
      mode: "cors"
    })
      .then((response) => response.json())
      .then((apiData: DataType[]) => {
        setData(apiData);

        const uniqueSectionNames = Array.from(new Set(apiData.map((item: DataType) => item.Section_Name)));
        const dynamicSectionNamesFilters: { text: string; value: string }[] = uniqueSectionNames.map((sectionName) => ({ text: sectionName as string, value: sectionName as string }));
        setSectionnameFilters(dynamicSectionNamesFilters);

        const uniqueUsecaseNames = Array.from(new Set(apiData.map((item: DataType) => item.Usecase_name)));
        const dynamicUsecaseFilters: { text: string; value: string }[] = uniqueUsecaseNames.map((usecaseName) => ({ text: usecaseName as string, value: usecaseName as string }));
        setUsecasenameFilters(dynamicUsecaseFilters);

        setProgress(100);
      })
  }, []);


  const handlecheckbox = (e: any) => {
    const { value, checked } = e.target;
    setData(prevData =>
      prevData.map(item =>
        item.Section_Name === value["Section_Name"] &&
        item.Usecase_name === value["UseCase_Name"] &&
        item.Description === value["Description"]
          ? { ...item, isChecked: checked }
          : item
      )
    );
  };
  

  const columns: ColumnsType<DataType> = [
    {
      title: 'Section Name',
      dataIndex: 'Section_Name',
      key: 'Section_Name',
      filters: sectionnameFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Section_Name === value,
      sorter: (a, b) => a.Section_Name.localeCompare(b.Section_Name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Usecase Name',
      dataIndex: 'Usecase_name',
      key: 'Usecase_name',
      filters: usecasenameFilters,
      onFilter: (value: string | number | boolean | bigint | undefined, record) =>
        record.Usecase_name === value,
      sorter: (a, b) => a.Usecase_name.localeCompare(b.Usecase_name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      width:"50%",
      sorter: (a, b) => a.Description.localeCompare(b.Description),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <span ref={multiselectRef}>
          <Checkbox
              checked={data.filter((val) => val["Section_Name"] === record["Section_Name"] && val["Usecase_name"] === record["Usecase_name"] && val['Description']===record['Description'])[0]["isChecked"]}
              value={{ "Section_Name": record.Section_Name, "UseCase_Name": record.Usecase_name, "Description": record.Description }} onChange={handlecheckbox}
            ></Checkbox>
            </span>
          <span ref={editRef}>
          <Button 
            disabled={data.filter((val) => val["isChecked"]).length > 1}
            type="link" onClick={(event) => {
            setSource(true);
            form.setFieldsValue({ 'Section_Name': record['Section_Name'], 'Usecase_name': record['Usecase_name'], 'Description': record['Description'] });
            setOldSectionName(record['Section_Name']);
            setOldUseCaseName(record['Usecase_name']);
            setOldUpdateName(record['Description']);
            showDrawer()
          }}
            icon={
              <EditOutlined style={{ color: 'green' }} />
            } />
          <Popconfirm
            title="Are you sure you want to delete this?"
            onConfirm={() => handleDelete(record)}
            onCancel={() => handleCancel(record)}
            ref={deleteRef}
            icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
            okText="Yes"
            cancelText="No"
          >
            <Button 
            disabled={data.filter((val) => val["isChecked"]).length > 1 && !record.isChecked}
            type="link" icon={<CloseOutlined style={{ color: 'red' }} />} />
          </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  const insertrecord = (value: any) => {
    setProgress(50);
    fetch(`${API_SERVER}updates/add/`,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: "cors",
        body: JSON.stringify(value)
      })
      .then((res) => {
        fetch(`${API_SERVER}updates/find`,
          {
            method: "GET",
            mode: "cors"
          })
          .then((response) => response.json())
          .then((apiData) => {
            setData(apiData);
            let val: any = [];
            for (let i = 0; i < apiData.length; i++) {
              val.push({
                sectionname: apiData[i]["Section_Name"],
                usecasename: apiData[i]["Usecase_name"],
                description: apiData[i]['Description']
              });
            }
            setUpdates(val);
            setProgress(100);
          })
      })
  }

  const updaterecord = (value: any) => {
    setProgress(50);
    fetch(`${API_SERVER}updates/update/${oldSectionName}/${oldUseCaseName}/${oldUpdateName}`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: "cors",
        body: JSON.stringify(value)
      })
      .then((res) => {
        fetch(`${API_SERVER}updates/find`,
          {
            method: "GET",
            mode: "cors"
          })
          .then((response) => response.json())
          .then((apiData) => {
            setData(apiData);
            let val: any = [];
            for (let i = 0; i < apiData.length; i++) {
              val.push({
                sectionname: apiData[i]["Section_Name"],
                usecasename: apiData[i]["Usecase_name"],
                description: apiData[i]['Description']
              });
            }
            setUpdates(val);
            setProgress(100);
          })

      })
  }

  const handleUpdates = (value: any) => {
    if (!source) {
      insertrecord(value);
    }
    else {
      updaterecord(value);
    }
    onClose();
  };

  const handleDelete = (record: DataType) => {
    let check = 0;
    for (let i = 0; i < data.length; i++)
      if (data[i]["isChecked"])
        check += 1;

    if (check === 0) {
      setProgress(50);
      fetch(`${API_SERVER}updates/delete/${record['Section_Name']}/${record['Description']}/${record['Usecase_name']}`, {
        method: 'DELETE',
        mode: 'cors',
      })
        .then((res) => {
          fetch(`${API_SERVER}updates/find`, {
            method: 'GET',
            mode: 'cors',
          })
            .then((response) => response.json())
            .then((apiData) => {
              setData(apiData);
              // add use case updated values for main cockpit page here
              setProgress(100);
            });
        })
        .catch((error) => console.error('Error deleting record:', error));
    }
    else {
      let count: number = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i]['isChecked']) {
          fetch(`${API_SERVER}updates/delete/${data[i]['Section_Name']}/${data[i]['Description']}/${data[i]['Usecase_name']}`, {
            method: 'DELETE',
            mode: 'cors',
          })
            .then((res) => {
              fetch(`${API_SERVER}updates/find`, {
                method: 'GET',
                mode: 'cors',
              })
                .then((response) => response.json())
                .then((apiData) => {
                  setData(apiData);
                  count += 1;
                  // add use case updated values for main cockpit page here
                });
            })
            .catch((error) => console.error('Error deleting record:', error));
          setProgress((count / check) * 100);
        }
      }
    }
      setProgress(100);


    setProgress(50);
    fetch(`${API_SERVER}updates/delete/${record['Section_Name']}/${record['Description']}/${record['Usecase_name']}`, {
      method: 'DELETE',
      mode: 'cors',
    })
      .then((res) => {
        fetch(`${API_SERVER}updates/find`, {
          method: 'GET',
          mode: 'cors',
        })
          .then((response) => response.json())
          .then((apiData) => {
            setData(apiData);
            let val: any = [];
            for (let i = 0; i < apiData.length; i++) {
              val.push({
                sectionname: apiData[i]["Section_Name"],
                usecasename: apiData[i]["Usecase_name"],
                description: apiData[i]['Description']
              });
            }
            setUpdates(val);
            setProgress(100);
          });
      })
      .catch((error) => console.error('Error deleting record:', error));
  };

  const handleCancel = (record: DataType) => {
    let rows: any = document.getElementsByClassName('ant-table-row');
    let updatedData = data.map(item => ({ ...item, isChecked: false }));
    for (let i = 0; i < rows.length; i++) {
      rows[i].style.backgroundColor = "white";
    }
 
    setData(updatedData);
  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const handleChange = (value: string) => {
    let val:any=[];
    for(let i=0;i<usecasedata.length;i++)
    {
      if(usecasedata[i]['Section_Name']===value && !val.includes({text:usecasedata[i]['Usecase_Name'],value:usecasedata[i]['Usecase_Name']}))
        val.push({text:usecasedata[i]['Usecase_Name'],value:usecasedata[i]['Usecase_Name']})
      setusecasenameDropdown(val);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className='updates'>
      <LoadingBar color='white' onLoaderFinished={() => setProgress(0)} />
      <div ref={tableRef}>
      <Table columns={columns} dataSource={data} bordered onChange={onChange} pagination={{ pageSize: 5 }} size='small' 
      rowClassName={(record: DataType) =>
        record.isChecked ? 'checked-row' : ''
      }/>
      </div>
      <div className='sectionbuttons'>
      <Button className="sectionbutton" ref={addRef} type="primary" onClick={() => { showDrawer();}}>
        Add Updates
      </Button>
      </div>
      <Drawer title="ADD UPDATES" placement="right" onClose={onClose} open={open} closeIcon={<CloseOutlined style={{ color: 'white' }} />} width={500}>
        <Form form={form} onFinish={handleUpdates}>
          <p> Section Name</p>

          <Form.Item name="Section_Name"
            rules={[{ required: true, message: 'Please enter Section Name!' },
            { whitespace: true, message: "Empty value not allowed!" },
            { max: 100, message: "Section name should not exceed 100" },
            { pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/, message: 'Section Name should start with a letter and can only contain letters, numbers, and underscores.', }
            ]}>
            <Select
              style={{ width: '100%' }}
              placeholder="Enter Section Name"
              onChange={handleChange}
              options={sectionnameDropdown}
            />
          </Form.Item>

          <p> Usecase Name</p>
          <Form.Item name="Usecase_name"
            rules={[{ required: true, message: 'Please enter Usecase Name!' },
            { whitespace: true, message: "Empty value not allowed!" },
            { max: 100, message: "Usecase name should not exceed 100" },
            { pattern: /^[a-z A-Z][a-zA-Z0-9_ !&@#*$()-]*$/, message: 'Usecase Name should start with a letter and can only contain letters, numbers, and underscores.', }
            ]}>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Usecase Name"
              options={usecasenameDropdown}
            />
          </Form.Item>
          <p> Description</p>
          <Form.Item
            name="Description"
            rules={[
              { required: true, message: "Please enter Description!" },
              {
                pattern: /^[^\s].*$/,
                message: "Description should not start with a space!",
              },
              { whitespace: true, message: "Empty value not allowed!" },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message:
                  "Description can contain any characters, including special characters.",
              },
              {
                max: 2000,
                message:"should not exceed more than 20",
              }
            ]}
          >
            <TextArea placeholder="Enter Description" autoComplete="off" showCount maxLength={2000}/>
          </Form.Item>
          <a
            className="Reset" onClick={onReset} >
            Reset
          </a>
          <Button className='Two' type="primary" htmlType="submit" style={{ marginTop: '30vh', alignItems: 'center' }}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default UpdatesPage;