import React, { useEffect, useRef, useState } from "react";
import "./Carditem.css";
import {
  models
} from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { Tooltip, Button, message, Spin } from "antd";
import * as config from '../../Config';
import { pca } from '../../index';
import {
  FundViewOutlined,
  DownloadOutlined,
  LoadingOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal} from '@azure/msal-react';
interface SwiperCardProps {
  config: any;
  link: any;
  titleRef: any;
  downloadRef: any;
  pbiviewRef: any;
  pbidashboardRef: any;
  usecase: any;
  description: any;
  fulllink: any;
  refreshRef:any;
}

const SwiperCard = (props: SwiperCardProps) => {
  const { instance } = useMsal();
  const canvasRef = useRef(null);
  const {
    titleRef,
    downloadRef,
    pbiviewRef,
    pbidashboardRef,
    refreshRef,
    link,
    usecase,
    description,
    fulllink,
  } = props;
  const [embedUrl, setEmbedUrl] = useState("");
  const [refreshat, setRefreshAT] = useState(true);
  const [isloading, setisloading] = useState(0);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [downloadready, setDownloadReady] = useState(false);
  const pbiref: any = React.useRef();

  
  useEffect(() => {
    if (!props.config || props.config.workspaceId === "" || props.link!) {
      return;
    }
    getEmbedUrl();
  }, [props.config, props.link]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setisloading((prevValue) => prevValue + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [isloading]);

  const getEmbedUrl = () => {
    try {
      fetch(
        `${props.config.powerBiApiUrl}v1.0/myorg/groups/${props.config.workspaceId}/reports/${props.link}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AT")}`,
          },
          method: "GET",
        }
      )
        .then((response) => {
          const errorMessage = [];
          errorMessage.push(
            "Error occurred while fetching the embed URL of the report"
          );
          errorMessage.push(`Request Id: ${response.headers.get("requestId")}`);

          response
            .json()
            .then((body) => {
              if (response.ok) {
                setEmbedUrl(body.embedUrl);
                setReportLoaded(true);
              } else {
                errorMessage.push(
                  `Error ${response.status}: ${body.error.code}`
                );
              }
            })
            .catch(() => {
              errorMessage.push(
                `Error ${response.status}: An error has occurred`
              );
            });
        })
        .catch((error) => {
          console.log(`Error in getting embedded URL ${error}`);
        });
    } catch (err) {
      console.log("error in URL");
      message.error("URL is not valid");
    }
  };

  const getaccess = async ()=>{
    try{
      setRefreshAT(false);
        if(pca.getAllAccounts.length>0)
        {
          // console.log('getting pbi token silently');
          await instance.acquireTokenSilent({
            scopes: config.scopeBase,
            account: pca.getAllAccounts()[0]
          }).then((response: any) => {
            localStorage.setItem('AT', response.accessToken);
            setRefreshAT(true);
            // getAccessToken();
          })
            .catch(async (err: any) => {
              if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                let request = {
                  scopes: config.scopeBase,
                  redirectURI: config.APP_URL
                };
                await instance.acquireTokenPopup(request).then((response: any) => {
                  localStorage.setItem('AT', response.accessToken);
                  setRefreshAT(true);
                })
              }
            })
        }
        else
        {
          // console.log('getting pbi token via popup');
          await instance.acquireTokenPopup({
            scopes: config.scopeBase,
            // account: pca.getAllAccounts()[0]
          }).then((response: any) => {
            localStorage.setItem('AT', response.accessToken);
            setRefreshAT(true);
          })
            .catch(async (err) => {
              if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                let request = {
                  scopes: config.scopeBase,
                  redirectURI: config.APP_URL
                };
                await instance.acquireTokenRedirect(request).then((response: any) => {
                  localStorage.setItem('AT', response.accessToken);
                  setRefreshAT(true);
                })
              }
            })
        }
        
      }
      catch (err) {
        setRefreshAT(true);
        console.log(err);
      }
  }

  const exportotstatus = async (expid: any) => {
    await fetch(
        `https://api.powerbi.com/v1.0/myorg/reports/${props.link}/exports/${expid}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("AT")}`,
            "Content-Type": "application/json",
          }
        }
      ).then((sres)=>{
        sres.json().then(async (sresjson)=>{
            if(sresjson['status']==='Succeeded')
            {
                await fetch(
                    `https://api.powerbi.com/v1.0/myorg/reports/${props.link}/exports/${expid}/file`,
                    {
                      method: "GET",
                      mode: "cors",
                      headers: {
                        "Authorization": `Bearer ${localStorage.getItem("AT")}`,
                        "Content-Type": "application/json",
                      }
                    }
                  ).then(async (tres: any)=>{
                        message.success('File ready to be downloaded.');
                        tres.blob().then((val: any)=>{
                            const url = URL.createObjectURL(val);
                            // Create a link element and initiate the download
                            const link = document.createElement('a');
                            link.href = url;
                            link.download =  `${usecase}.pdf`;
                            document.body.appendChild(link);
                            link.click();
                            // Clean up by removing the link element
                            URL.revokeObjectURL(url);
                            document.body.removeChild(link);
                            setDownloadReady(false);   
                        })
                    })
                    .catch((err:any) => {
                        message.error('Error while downloading file');
                        setDownloadReady(false);
                      });
            }
            else{
                setTimeout(()=>{message.warning('File is getting exported, please wait.');exportotstatus(expid)},10000);
            }
        })
      })
      .catch((err:any) => {
        message.error('Error while downloading file');
        setDownloadReady(false);
      });
  }

  const download = async () => {
    if (!reportLoaded){
      setDownloadReady(false);
      message.warning("Report is loading, please wait.");
      return;
    }
    setDownloadReady(true);
      await fetch(
        `https://api.powerbi.com/v1.0/myorg/reports/${props.link}/ExportTo`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("AT")}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "format": "pdf"
          }),
        }
      )
        .then((response: any) => {
          response.json().then((res: any) => {
            message.warning('Download has been queued, please wait.'); 
            let expid = res['id'];
            exportotstatus(expid);
            })
        })
        .catch((err:any) => {
          message.error('Error while downloading file');
          setDownloadReady(false);
        });    
  };

  // Power BI REST API call to refresh User Permissions in Power BI
  // Refreshes user permissions and makes sure the user permissions are fully updated
  // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
  const tryRefreshUserPermissions = () => {
    fetch(`${props.config.powerBiApiUrl}v1.0/myorg/RefreshUserPermissions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AT")}`,
      },
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          console.log("User permissions refreshed successfully.");
        } else {
          if (response.status === 429) {
            console.error(
              "Permissions refresh will be available in up to an hour."
            );
          } else {
            console.error(response);
          }
        }
      })
  };

  const loading = (
    <div id="print">
      <div ref={titleRef}>
        <p
          style={{
            fontSize: "4vh",
            color: "rgb(60,40,155)",
            marginLeft: "1vw",
            marginTop: "0.5vh",
            marginBottom: "0vh",
            fontWeight: "bolder",
            fontFamily: "AB Gilroy",
          }}
        >
          {usecase}
        </p>
        <p
          style={{
            fontSize: "2vh",
            color: "rgb(60,40,155)",
            marginLeft: "1vw",
            marginTop: "0px",
            marginBottom: "0.5vw",
            fontFamily: "AB Gilroy",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxHeight: "2.3vh", 
            display: "inline-block", 
          }}
          title={description.length > 170 ? description : undefined}
        >
          {description.length > 170
            ? `${description.substring(0, 170)}...`
            : description}
        </p>
      </div>
      <Tooltip
        title="Refresh Access Token"
        placement="left"
      >
        <Button
          className="refreshtoken"
          type="link"
          ref={refreshRef}
          onClick={()=>{getaccess()}}
          icon={<ReloadOutlined />}
        />
      </Tooltip>
      {downloadready && <Spin
        indicator={
          <LoadingOutlined
            className="downloadloader"
            spin
          />
        }
      />}
      <Tooltip
        title={!downloadready ? "Download PDF" : "Please wait"}
        placement="bottomLeft"
      >
        <Button
          className="downloadimage"
          type="link"
          disabled={downloadready}
          ref={downloadRef}
          icon={<DownloadOutlined />}
          onClick={() => {
            download();
          }}
        />
      </Tooltip>
      <Tooltip title="View in PowerBI" placement="bottom">
        <Button
          className="viewinpbi"
          type="link"
          ref={pbiviewRef}
          icon={<FundViewOutlined />}
          onClick={() => {
            window.open(fulllink);
          }}
        />
      </Tooltip>

      <div
        id="dashboard"
        ref={(element) => {
          pbidashboardRef.current = element;
          pbiref.current = element;
        }}
      >
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        {refreshat && <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: link,
            embedUrl: embedUrl,
            pageView: "fitToWidth",
            accessToken: "" + localStorage.getItem("AT"),
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                (event: any) => {
                  setReportLoaded(true);
                },
              ],
              [
                "rendered",
                function (event: any) {
                  // captures logic once report has been rendered
                },
              ],
              ["error", function (event: any) {setReportLoaded(false);}],
              [
                "visualClicked",
                () => {
                  // captures logic when user interacts with dashboard
                },
              ],
              [
                "pageChanged",
                (event: any) => {
                  // captures logic when page is changed in report
                },
              ],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={(embeddedReport) => {}}
        />}
      </div>
    </div>
  );
  return <div>{loading}</div>;
};

export default SwiperCard;