import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";
import "./Sidenav.css";

const { Sider } = Layout;

type MenuItem = {
  label: string;
  key: string;
  path: string;
};

interface SidenavProps {
  menuItems: MenuItem[];
  sidenavRef: any;
  page?: string;
  setShowDashboard: React.Dispatch<React.SetStateAction<boolean>>;
  dashboard: any;
}

const Sidenav: React.FC<SidenavProps> = ({
  menuItems,
  sidenavRef,
  page,
  setShowDashboard,
  dashboard,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = useState(false);
  const [key, setKey] = useState("1");
  const currentPath = location.pathname;

  useEffect(() => {
    const selectedItem = menuItems.find((item) => item.path === currentPath);
    if (selectedItem) {
      setKey(selectedItem.key);
      setSettings(true);
    } else {
      setKey("1");
      setSettings(false);
    }
  }, [currentPath, menuItems]);

  
  return (
    <Sider ref={sidenavRef} >
      {page && page.includes("settings") ? (
        <Menu
          className="side-nav-1"
          onClick={(e) => {
            setKey(e.key);
          }}
          style={{ width: "20vw !important" }}
        >
          {menuItems.map((item, index) => (
            <Menu.Item key={item.key} id={`sidenav${index}`}>
              <Link to={item.path}>
              {/* {item.label.length > 24 ? `${item.label.substring(0, 23)}...` : item.label} */}
              {item.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <Menu
          className="side-nav-1"
          selectedKeys={[key]}
          onClick={(e) => {
            setKey(e.key);
            setShowDashboard(false);
          }}
          style={{ width: "20vw !important" }}
        >
          {menuItems.map((item, index) => (
            <Menu.Item key={item.key} id={`sidenav${index}`}>
              {key && item.key != key ? 
                (<Link to={item.path}>
                {/* {item.label.length > 24 ? `${item.label.substring(0, 23)}...` : item.label} */}
                {item.label}
                </Link>)
               : 
               <>
                <Link to={item.path}>
                {/* {item.label.length > 24 ? `${item.label.substring(0, 23)}...` : item.label} */}
                {item.label}
                </Link>
                <li className="sidenavtree">
                {dashboard.map((dbitem: any, dbindex: any) => {
                      return dbitem["Section_Name"] == item["label"] && 
                      <Tooltip style={{zIndex:"2 !important"}} title={dbitem['Usecase_Name']}>
                        <ul className="sidenavtreeitem">
                          {
                            dbitem["Usecase_Name"].length>30?
                            "→ "+dbitem["Usecase_Name"].substring(0,20)+'...':
                            '→ '+dbitem['Usecase_Name']
                          }
                        </ul>
                      </Tooltip>
                })}
                </li>
                </>
              }
            </Menu.Item>
          ))}
        </Menu>
      )}
    </Sider>
  );
};

export default Sidenav;
