import React, { useEffect, useState } from "react";
import {
  Button,
  Tour,
  Tooltip,
  Input,
  AutoComplete,
  Menu,
  Dropdown,
  message,
} from "antd";
import "./Topnav.css";
import { Link } from "react-router-dom";
import {
  BellOutlined,
  ReadFilled,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";

interface TopnavProps {
  homeRef: any;
  steps: any;
  stepssetting: any;
  settingsRef: any;
  prefstepssetting: any;
  notificationsRef: any;
  Dashboardsteps: any;
  page: any;
  usecaseauto: any;
  menuItems: any;
  dashboard: any;
  setUsecase: any;
  setDescription: any;
  setLink: any;
  setFullLink: any;
  usecase: any;
  description: any;
  link: any;
  fulllink: any;
  setPage: any;
  setShowDashboard: any;
  // notifications: any;
  // setNotifications: any;
}

const Topnav: React.FC<TopnavProps> = ({
  homeRef,
  steps,
  stepssetting,
  settingsRef,
  prefstepssetting,
  notificationsRef,
  Dashboardsteps,
  page,
  usecaseauto,
  menuItems,
  dashboard,
  setUsecase,
  setDescription,
  setLink,
  setFullLink,
  setPage,
  setShowDashboard,
  // notifications,
  // setNotifications,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string }[]>(usecaseauto);
  const [searchValue, setSearchValue] = useState<string>("");

  // const notificationsMenu = (
  //   <Menu>
  //     {notifications &&
  //       notifications.length > 0 &&
  //       notifications.map((notification: any, index: any) => (
  //         <Menu.Item key={notification.id}>
  //           <div className="notification-item">
  //             <div className="notification-text">{notification.message}</div>
  //             {!notification.read && (
  //               <Tooltip title="Mark as read" placement="right">
  //                 <ReadFilled
  //                   className="read-icon"
  //                   onClick={(event: any) => {
  //                     let val = [];
  //                     for (let i = 0; i < notifications.length; i++)
  //                       if (i != index) val.push(notifications[i]);
  //                     setNotifications(val);
  //                   }}
  //                 />
  //               </Tooltip>
  //             )}
  //           </div>
  //         </Menu.Item>
  //       ))}
  //   </Menu>
  // );

  useEffect(() => {
    setOptions(usecaseauto);
  }, [usecaseauto]);

  const handleSearch = (value: string) => {
    if (!value) {
      setOptions(usecaseauto);
      return;
    }
    const filteredOptions = usecaseauto.filter((option: { value: string }) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleViewClick = (
    usecaseName: string,
    description: string,
    link: string
  ) => {
    setUsecase(usecaseName);
    setDescription(description);
    setLink(link.split("/")[6]);
    setFullLink(link);
    setShowDashboard(true);
    setPage("dashboard");
  };

  const onSelect = (value: string) => {
    if (document.getElementById("gobacktomain")) {
      document.getElementById("gobacktomain")?.click();
      let sectionname = "";
      for (let i = 0; i < dashboard.length; i++)
        if (dashboard[i]["Usecase_Name"] === value) {
          sectionname = dashboard[i]["Section_Name"];
          break;
        }
      //checking if usecase exist or not
      if (sectionname !== "") {
        let sectionnameindex = -1;
        //finding section name index and performing click on specific section name on side nav
        for (let i = 0; i < menuItems.length; i++)
          if (menuItems[i]["label"] === sectionname) {
            sectionnameindex = i;
            break;
          }

        let elem: any = document.getElementById(`sidenav${sectionnameindex}`);
        elem.children[0].children[0].click();
        let val = [];
        for (let i = 0; i < dashboard.length; i++)
          val.push(dashboard[i]["Usecase_Name"]);
        let usecaseindex = val.indexOf(value);
        setUsecase(value);
        setDescription(dashboard[usecaseindex]["Description"]);
        setLink(dashboard[usecaseindex]["Link"].split("/")[6]);
        setFullLink(dashboard[usecaseindex]["Link"]);
        setTimeout(() => {
          document.getElementById(`usecase${usecaseindex}`)?.click();
        }, 500);
        setSearchValue("");
      }
    } else {
      try {
        let sectionname = "";
        for (let i = 0; i < dashboard.length; i++)
          if (dashboard[i]["Usecase_Name"] === value) {
            sectionname = dashboard[i]["Section_Name"];
            break;
          }
        //checking if usecase exist or not
        if (sectionname !== "") {
          setShowDashboard(false);
          let sectionnameindex = -1;
          //finding section name index and performing click on specific section name on side nav
          for (let i = 0; i < menuItems.length; i++)
            if (menuItems[i]["label"] === sectionname) {
              sectionnameindex = i;
              break;
            }
          let elem: any = document.getElementById(`sidenav${sectionnameindex}`);
          elem.children[0].children[0].click();
          setShowDashboard(false);
          // filtering usecase
          let val = [];
          for (let i = 0; i < dashboard.length; i++)
            val.push(dashboard[i]["Usecase_Name"]);
          let usecaseindex = val.indexOf(value);
          handleViewClick(
            value,
            dashboard[usecaseindex]["Description"],
            dashboard[usecaseindex]["Link"]
          );
          setSearchValue("");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const error = () => {
    message.warning("Notifications will be implemented soon!");
  };

  return (
    <div className="topnav-container">
      <Link to="/">
        <p className="topnav-text" ref={homeRef}>
          NDC Cockpit
        </p>
      </Link>
      <AutoComplete
        className="search"
        options={options}
        allowClear={true}
        onSelect={onSelect}
        onSearch={handleSearch}
        notFoundContent={"Not Found!"}
        value={searchValue}
        onChange={(value) => setSearchValue(value)}
      >
        <Input placeholder="Search Usecases" prefix={<SearchOutlined />} />
      </AutoComplete>
      <>
        <Button
          className="topnav-guide"
          type="primary"
          icon={<ReadFilled />}
          onClick={() => setOpen(true)}
        >
          Guide
        </Button>
        {page !== "settings" &&
        page !== "settings/preference" &&
        page !== "dashboard" ? (
          <Tour
            open={open}
            onClose={() => setOpen(false)}
            mask={true}
            steps={steps}
          />
        ) : page === "settings/preference" ? (
          <Tour
            open={open}
            onClose={() => setOpen(false)}
            mask={true}
            steps={prefstepssetting}
          />
        ) : page === "dashboard" ? (
          <Tour
            open={open}
            onClose={() => setOpen(false)}
            mask={true}
            steps={Dashboardsteps}
          />
        ) : (
          <Tour
            open={open}
            onClose={() => setOpen(false)}
            mask={true}
            steps={stepssetting}
          />
        )}
      </>

      <Tooltip title="Settings">
        <Link to="/settings/section">
          <SettingOutlined className="topnav-setting-icon" ref={settingsRef} />
        </Link>
      </Tooltip>
      {/* {notifications && notifications.length > 0 ? (
        <Dropdown overlay={notificationsMenu} trigger={["click"]}>
          <div>
            {notifications && notifications.length > 0 && (
              <Tooltip
                title={`There is     ${notifications.length} unread notifications`}
              >
                <BellOutlined
                  className="topnav-notification-icon-present"
                  ref={notificationsRef}
                />
              </Tooltip>
            )}
          </div>
        </Dropdown>
      ) : (
        <div>
          <Tooltip title={`There are no new notifications`}>
            <BellOutlined
              className="topnav-notification-icon"
              ref={notificationsRef}
            />
          </Tooltip>
        </div>
      )} */}
      <Tooltip title="Notifications">
        <BellOutlined
          className="topnav-notification-icon"
          ref={notificationsRef}
          onClick={error}
        />
      </Tooltip>
      <img
        className="topnav-logo"
        src={process.env.PUBLIC_URL + "/cencora logo.png"}
        alt="Logo"
      />
    </div>
  );
};

export default Topnav;
