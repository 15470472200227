import React, { useState, useEffect } from 'react';
import { Tabs, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import './SettingsPage.css';

const { TabPane } = Tabs;

interface SettingstabProps {
  selectedTab: string;
  tabRef: any;
}

const Settingstab: React.FC<SettingstabProps> = ({ selectedTab, tabRef }) => {
  const [activeKey, setActiveKey] = useState("section");
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    navigate(`/settings/${key}`);
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const tabKey = pathParts[pathParts.length - 1];
    setActiveKey(tabKey);
  }, [location.pathname]);

  return (
    <>
    
      <div className="tabs-container" ref={tabRef}>
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          type="card"
          // centered
          tabPosition='top'
        >
          <TabPane tab="Section" key="section">
          </TabPane>
          <TabPane tab="Usecase" key="use-case">
          </TabPane>
          <TabPane tab="Preference" key="preference">
          </TabPane>
          <TabPane tab="Impact" key="impact">
          </TabPane>
          <TabPane tab="Updates" key="updates">
          </TabPane>
        </Tabs>
      </div>
      {activeKey && <div className='topmaintable'>
        {
          activeKey === 'section' &&
          <Card className="settingscard" title="Section Table" bordered={true}>
            <p><b>Section Name - </b>Name of section, as displayed on side nav bar</p>
            <p><b>Descripition - </b>Description of section, as displayed on main cockpit page</p>
            <p><b>Action - </b>Multi select, edit and delete</p>
          </Card>
        }
        {
          activeKey === 'use-case' &&
          <Card className="settingscard usecasecard" title="Usecase Table" bordered={true}>
            <p><b>Section Name - </b>Name of section, as displayed on side nav bar</p>
            <p><b>Usecase Name - </b>Name of Usecase</p>
            <p><b>Descripition - </b>Description of section, as displayed on main cockpit page</p>
            <p><b>Link - </b>Web URL of PowerBI dashboard</p>
            <p><b>Action - </b>Multi select, edit and delete</p>
          </Card>
        }
        {
          activeKey === 'preference' &&
          <Card className="settingscard" title="Preference Table" bordered={true}>
            <p><b>Section Name - </b>Name of section, as displayed on side nav bar</p>
            <p><b>Usecase Name - </b>Name of Usecase</p>
            <p><b>Action - </b>Multi select delete</p>
          </Card>
        }
        {
          activeKey === 'impact' &&
          <Card className="settingscard impactcard" title="Impact Table" bordered={true}>
            <p><b>Section Name - </b>Name of section, as displayed on side nav bar</p>
            <p><b>Usecase Name - </b>Name of Usecase</p>
            <p><b>Impact Descripition - </b>Description of Impact, as displayed on main cockpit page</p>
            <p><b>Action - </b>Multi select, edit and delete</p>
          </Card>
        }
        {
          activeKey === 'updates' &&
          <Card className="settingscard updatescard" title="Updates Table" bordered={true}>
            <p><b>Section Name - </b>Name of section, as displayed on side nav bar</p>
            <p><b>Usecase Name - </b>Name of Usecase</p>
            <p><b>Descripition - </b>Description of Section, as displayed on main cockpit page</p>
            <p><b>Action - </b>Multi select, edit and delete</p>
          </Card>
        }
      </div>}
    </>
  );
};

export default Settingstab;
