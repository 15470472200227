import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as config from "./Config";

export const pca = new PublicClientApplication(generateMsalConfig());

ReactDOM.render(
<React.StrictMode>
    <App />
  </React.StrictMode>,
document.getElementById('root'));

function generateMsalConfig() {
    const msalConfig = {
        
        auth: {
            clientId: config.clientId,
            authority: config.authorityUrl,
            redirectUri: config.APP_URL
        },
        clientCertificate: {
            thumbprint: 'D9AEB5398DAD6E749B64EDA4A14CBDA2806317DB',
            privateKey:  '98f40eca-e9be-4fe5-96b8-5b2247cba18c',
          },
          cache:{
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true
           }
    };

    return msalConfig;
}